<div [formGroup]="accountsForm">
  <div class="desc">
    Please provide details about banks/payment institutions where the company
    has accounts.
  </div>
  <app-account-form
    *ngFor="let account of accounts; trackBy: trackByFn; let i = index"
    [index]="i"
    [countries]="countries"
    [fields]="fields"
    [account]="account"
    [isReviewed]="isReviewed"
    (accountChange)="onAccountChange($event)"
    (accountRemove)="onAccountRemove($event)"
    (isComplete)="setComplete(account.id, $event)"
  ></app-account-form>
  <button
    *ngIf="!isReviewed"
    mat-flat-button
    color="accent"
    class="g-small"
    [disabled]="isAdding"
    [appButtonLoader]="isAdding"
    (click)="addAccount()"
    data-testid="add-bank-payment-institution"
  >
    Add another bank/payment institution
  </button>
</div>
