<div [formGroup]="groupMembersForm">
  <div class="desc">
    Please provide all details about the companies participating in the same
    group with your company.
  </div>
  <app-group-member-form
    *ngFor="let groupMember of groupMembers; trackBy: trackByFn; let i = index"
    [index]="i"
    [countries]="countries"
    [isReviewed]="isReviewed"
    [fields]="fields"
    [groupMember]="groupMember"
    (groupMemberChange)="onGroupMemberChange($event)"
    (groupMemberRemove)="onGroupMemberRemove($event)"
    (isComplete)="setComplete(groupMember.id, $event)"
  ></app-group-member-form>
  <button
    *ngIf="!isReviewed"
    mat-flat-button
    color="accent"
    class="g-small"
    [disabled]="isAdding"
    [appButtonLoader]="isAdding"
    (click)="addGroupMember()"
    data-testid="add-group-member"
  >
    Add another group member
  </button>
</div>
