import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ErrorService } from 'src/app/shared/error-dialog/error.service';
import {
  descriptionMaxLength,
  getDateFormat,
} from 'src/app/shared/helpers/various-helpers.helper';
import { TransferService } from '../transfer.service';
import { DashboardService } from 'src/app/dashboard/dashboard.service';
import { AccountDetails } from 'src/app/dashboard/models/account-details.model';
import { AllAccounts } from 'src/app/dashboard/models/all-accounts.model';
import { transferAmountValidator } from '../shared/transfer-amount.validator';
import { Transfer, TransferWithOtp } from '../shared/models/transfer.model';
import { TransferType } from '../shared/enum/transfer-type.enum';

@Component({
  templateUrl: './in-between.component.html',
  styleUrls: ['./in-between.component.scss'],
})
export class InBetweenComponent implements OnInit {
  isLoading: boolean = false;
  isTransferring: boolean = false;
  isConfirmationStep: boolean = false;
  accounts: AccountDetails[] = [];
  allAccounts?: AllAccounts;
  transactionId?: number;
  accountOwnerGuid!: string;
  otpVerifyToken!: string; // otp token to verify transaction in core banking (not the sms)

  transferForm: FormGroup = this.fb.group(
    {
      accounts: this.fb.group({
        fromAccount: [null, Validators.required],
        toAccount: [null, Validators.required],
      }),
      otherFields: this.fb.group({
        amount: [
          null,
          [Validators.required, Validators.pattern('^[0-9]+[.]?[0-9]{0,2}$')],
        ],
        executionDate: [null, Validators.required],
        payerDescription: [
          null,
          [
            Validators.required,
            Validators.pattern('^[a-zA-Z0-9 ]*$'),
            Validators.maxLength(descriptionMaxLength),
          ],
        ],
      }),
    },
    {
      validators: [transferAmountValidator()],
    }
  );

  constructor(
    private fb: FormBuilder,
    private errorService: ErrorService,
    private transferService: TransferService,
    private dashboardService: DashboardService
  ) {}

  ngOnInit(): void {
    this.getInitialData();
  }

  cancelTransfer(event: {
    shouldReload: boolean;
    restartProcess: boolean;
  }): void {
    this.transactionId = undefined;
    this.isConfirmationStep = false;
    if (event.shouldReload) {
      this.getInitialData();
    } else {
      this.setDefaultValues();
    }
  }

  back(): void {
    this.transactionId = undefined;
    this.isConfirmationStep = false;
  }

  async goToConfirmationStep(): Promise<void> {
    const transfer = this.prepareTransferBody();
    (await this.transferService.createTransfer(transfer)).subscribe(
      (data: { otpVerifyToken: string }) => {
        this.otpVerifyToken = data.otpVerifyToken;
        this.isConfirmationStep = true;
      },
      (error) => {
        error.status === 400
          ? this.errorService.showErrorDialog(error.error.message)
          : this.errorService.showErrorDialog();
      }
    );
  }

  private prepareTransferBody(): Transfer {
    const transfer: Transfer = {
      transferType: TransferType.INBETWEEN,
      accountOwnerGuid: this.accountOwnerGuid,
      fromAccountId: this.fromAccountControl.value.id,
      fromAccountGuid: this.fromAccountControl.value.guid,
      toAccountId: this.toAccountControl?.value.id,
      toAccountGuid: this.toAccountControl?.value.guid,
      fromCurrency: 'EUR',
      benRateCurrency: 'EUR',
      benRateValue: '1',
      executionDate: this.executionDateControl?.value,
      amount: this.amountControl?.value,
      toAmount: this.amountControl?.value,
      payerDescription: this.payerDescriptionControl?.value,
      beneficiaryDescription: this.payerDescriptionControl?.value,
    };
    return transfer;
  }

  async transfer(): Promise<void> {
    this.isTransferring = true;
    const transferBody = this.prepareTransferBody();
    const body: TransferWithOtp = {
      ...transferBody,
      otp: '',
      otpVerifyToken: this.otpVerifyToken,
    };
    (await this.transferService.transferInBetween(body)).subscribe(
      () => {
        this.isTransferring = false;
        this.transactionId = 1;
      },
      () => {
        this.isTransferring = false;
        this.errorService.showErrorDialog();
      }
    );
  }

  private getInitialData(): void {
    this.isLoading = true;
    this.dashboardService.getAccounts(TransferType.INBETWEEN).subscribe(
      (accounts) => {
        this.allAccounts = accounts;
        this.accountOwnerGuid = accounts.accountOwnerGuid;
        this.accounts = [accounts.iban, ...accounts.cards, ...accounts.wallets];
        this.setDefaultValues();
        this.isLoading = false;
      },
      () => {
        this.isLoading = false;
        this.errorService.showErrorDialog();
      }
    );
  }

  private async setDefaultValues(): Promise<void> {
    this.transferForm.reset();
    // sets IBAN Account as 'from'
    this.fromAccountControl.setValue(this.allAccounts?.iban);
    this.executionDateControl.setValue(getDateFormat(new Date()));
    this.payerDescriptionControl.setValue('Internal Transfer');

    // sets NOT IBAN account as 'to'
    this.toAccountControl.setValue(this.allAccounts?.wallets[0]);
  }

  get accountsGroup(): FormGroup {
    return this.transferForm.get('accounts') as FormGroup;
  }
  get fromAccountControl(): FormControl {
    return this.accountsGroup.get('fromAccount') as FormControl;
  }
  get toAccountControl(): FormControl {
    return this.accountsGroup.get('toAccount') as FormControl;
  }
  get otherFieldsGroup(): FormGroup {
    return this.transferForm.get('otherFields') as FormGroup;
  }
  get amountControl(): FormControl {
    return this.otherFieldsGroup.get('amount') as FormControl;
  }
  get executionDateControl(): FormControl {
    return this.otherFieldsGroup.get('executionDate') as FormControl;
  }
  get payerDescriptionControl(): FormControl {
    return this.otherFieldsGroup.get('payerDescription') as FormControl;
  }
}
