<div
  fxLayout
  fxLayoutGap="20px"
  fxLayout.lt-sm="column"
  class="picture-section"
>
  <img
    class="avatar"
    [src]="avatarUrl || '/assets/img/user-big.svg'"
    alt="avatar"
  />
  <div fxLayout="column">
    <div>
      <div fxLayout="row" fxLayoutAlign="space-between none" class="first-line">
        <div class="title">Your profile image</div>
        <div
          class="remove clickable"
          (click)="deleteAvatar()"
          *ngIf="avatarUrl"
        >
          Remove
        </div>
      </div>
      <div class="desc">
        <span *ngIf="!avatarUrl">No image</span>
        (.png or .jpg. Up to {{ maxFileSizeMB }}MB, at least {{ minWidth }} x
        {{ minHeight }}px)
      </div>
      <ngx-file-drop
        dropZoneLabel="Drop the image here or"
        accept=".jpg,.jpeg,.png,.heic,.tif,.tiff"
        [showBrowseBtn]="true"
        [multiple]="false"
        [directory]="false"
        browseBtnLabel="browse"
        dropZoneClassName="drop-zone"
        contentClassName="content"
        browseBtnClassName="browse-button"
        (onFileDrop)="dropped($event[0])"
      ></ngx-file-drop>
      <div *ngIf="errorMessage" class="desc error">
        {{ errorMessage }}
      </div>
      <div *ngIf="isUploading" class="desc">Uploading...</div>
    </div>

    <div
      fxLayout="column"
      class="select-field"
      [formGroup]="userStateForm"
      *ngIf="isAdminProfile"
    >
      <div fxLayout="column">
        <label>Status</label>
        <mat-form-field>
          <mat-select
            placeholder="Please select"
            name="userState"
            formControlName="userState"
          >
            <mat-option
              *ngFor="let state of userStatesMap | keyvalue"
              [value]="state.key"
              >{{ state.value }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
      <div
        fxLayout="column"
        *ngIf="userStateControl?.value === UserState.CLOSED"
      >
        <label for="closeReason">Termination reason</label>
        <mat-form-field appearance="outline">
          <mat-select
            placeholder="Please select"
            fxLayout="column"
            fxLayoutGap="14px"
            name="closeReason"
            formControlName="closeReason"
          >
            <mat-option
              *ngFor="let reason of closeReasonNamesMap | keyvalue"
              [value]="reason.key"
              >{{ reason.value }}</mat-option
            >
          </mat-select>
          <mat-error
            data-testid="validation-message-box"
            *ngIf="closeReasonControl?.hasError('required')"
            >Required</mat-error
          >
        </mat-form-field>
      </div>

      <button
        mat-flat-button
        color="accent"
        class="g-small"
        [disabled]="userStateForm.invalid || userStateForm.pristine"
        [appButtonLoader]="isSaving"
        (click)="saveUserState()"
      >
        Save status
      </button>
      <button
        mat-flat-button
        color="accent"
        class="g-small lock-button"
        [appButtonLoader]="isLockingOrUnlockingUser"
        (click)="lockOrUnlockUser()"
      >
        {{ isLocked ? 'Unlock' : 'Lock' }}
      </button>
      <div class="review-date">
        Review Date: {{ reviewDate | date: 'dd/MM/yyyy' }}
      </div>
    </div>
  </div>
</div>
<mat-divider *ngIf="isAdminProfile"></mat-divider>
