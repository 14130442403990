import { AbstractControl, FormControl, ValidatorFn } from '@angular/forms';
import { CurrencyNames } from 'src/app/dashboard/models/currencyNames';
import { WalletInstance } from 'src/app/dashboard/models/walletInstance';

export function forexAmountValidator(
  getWallet: () => WalletInstance
): ValidatorFn {
  return (formGroup: AbstractControl): null => {
    const fromCurrControl = formGroup.get('fromCurr') as FormControl;
    const amountControl = formGroup.get('amount') as FormControl;

    const currency = (fromCurrControl.value as CurrencyNames)?.short;
    const amount = amountControl.value;

    amountControl.updateValueAndValidity({ onlySelf: true });

    if (currency) {
      const wallet = getWallet();
      const balance = wallet ? wallet[currency].availableBalance || 0 : 0;

      // if (amount > balance) {
      //   amountControl.setErrors({ max: true });
      // } // core banking system will check the balance
    }

    if (amount < 2) {
      amountControl.setErrors({ min: true });
    }

    return null;
  };
}
