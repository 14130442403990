<div class="buttons-container" fxLayoutAlign="start center" fxLayoutGap="12px">
  <button
    *ngIf="!environment.DISABLE_WALLET"
    mat-flat-button
    class="g-medium"
    routerLink="in-between"
    routerLinkActive="active"
    color="accent"
  >
    Internal
  </button>
  <button
    mat-flat-button
    class="g-medium"
    routerLink="e2e-now"
    routerLinkActive="active"
    color="accent"
    data-testid="e2e-now-tab"
  >
    e2e Now
  </button>
  <button
    mat-flat-button
    class="g-medium"
    routerLink="external"
    routerLinkActive="active"
    color="accent"
    data-testid="domestic-and-international-tab"
  >
    Domestic & International
  </button>
  <button
    *ngIf="isCorporateUser"
    mat-flat-button
    class="g-medium"
    routerLink="mass-payments"
    routerLinkActive="active"
    color="accent"
    data-testid="mass-payments-tab"
  >
    Mass Payments
  </button>
  <button
    mat-flat-button
    class="g-medium"
    routerLink="recurring"
    routerLinkActive="active"
    color="accent"
  >
    Recurring
  </button>
  <button
    mat-flat-button
    class="g-medium"
    routerLink="favourites"
    routerLinkActive="active"
    color="accent"
  >
    Favourites
  </button>
</div>
<router-outlet></router-outlet>
