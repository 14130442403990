<h2>Business</h2>
<ng-container [formGroup]="form" *ngIf="form">
  <div formGroupName="entityForm" class="entity">
    <!-- COMPANY NAME -->
    <div fxLayout="column" class="field">
      <label for="businessName">Company legal name</label>
      <mat-form-field appearance="outline">
        <input matInput name="businessName" formControlName="businessName" />
        <mat-error *ngIf="businessNameControl?.hasError('required')"
          >Required</mat-error
        >
        <mat-error *ngIf="businessNameControl?.hasError('pattern')"
          >Invalid character</mat-error
        >
        <mat-error *ngIf="businessNameControl?.hasError('maxlength')"
          >Too many characters</mat-error
        >
      </mat-form-field>
    </div>

    <div fxLayout="column" class="field">
      <label for="email">Email</label>
      <mat-form-field appearance="outline">
        <input matInput name="email" formControlName="email" />
      </mat-form-field>
    </div>

    <div fxLayout="column" class="field phone-number">
      <label for="cellPhoneNumber">Phone number</label>
      <ngx-intl-tel-input
        [enablePlaceholder]="false"
        [searchCountryFlag]="true"
        [separateDialCode]="true"
        name="cellPhoneNumber"
        formControlName="cellPhoneNumber"
      >
      </ngx-intl-tel-input>
      <div class="mat-form-field">
        <div class="mat-form-field-appearance-outline">
          <div class="mat-form-field-wrapper" style="margin-top: 0"></div>
        </div>
      </div>
    </div>

    <!-- TRADING NAME -->
    <div fxLayout="column" class="field">
      <label for="businessTradingName">Trading name (if applicable)</label>
      <mat-form-field appearance="outline">
        <input
          matInput
          name="businessTradingName"
          formControlName="businessTradingName"
        />
        <mat-error *ngIf="businessTradingNameControl?.hasError('pattern')"
          >Invalid character</mat-error
        >
        <mat-error *ngIf="businessTradingNameControl?.hasError('maxlength')"
          >Too many characters</mat-error
        >
      </mat-form-field>
    </div>

    <!-- COMPANY LEGAL STATUS -->
    <div fxLayout="column" class="field">
      <label for="legalStatus">Company legal status</label>
      <mat-form-field appearance="outline" class="field">
        <mat-select
          placeholder="Please select"
          fxLayout="column"
          fxLayoutGap="14px"
          name="legalStatus"
          formControlName="legalStatus"
        >
          <mat-option
            *ngFor="let legalStatus of legalStatuses"
            [value]="legalStatus[1]"
            [attr.data-testid]="
              'legalStatus-option-' +
              legalStatus[1].split(' ').join('-').toLowerCase()
            "
            >{{ legalStatus[0] }}</mat-option
          >
        </mat-select>
        <mat-error *ngIf="legalStatusControl?.hasError('required')"
          >Required</mat-error
        >
      </mat-form-field>
    </div>

    <!-- Enable when Other option is selected -->
    <div
      fxLayout="column"
      class="field"
      *ngIf="legalStatusControl?.value === LegalStatus['Other type of Company']"
    >
      <label for="otherLegalStatus"
        >Please specify if Other type of Company</label
      >
      <mat-form-field appearance="outline">
        <input
          matInput
          name="otherLegalStatus"
          formControlName="otherLegalStatus"
        />
        <mat-error *ngIf="otherLegalStatusControl?.hasError('required')"
          >Required</mat-error
        >
        <mat-error *ngIf="otherLegalStatusControl?.hasError('pattern')"
          >Invalid character</mat-error
        >
        <mat-error *ngIf="otherLegalStatusControl?.hasError('maxlength')"
          >Too many characters</mat-error
        >
      </mat-form-field>
    </div>

    <!-- REGISTRATION NUMBER -->
    <div fxLayout="column" class="field">
      <label for="registrationNumber">Registration number</label>
      <mat-form-field appearance="outline">
        <input
          matInput
          name="registrationNumber"
          formControlName="registrationNumber"
        />
        <mat-error *ngIf="registrationNumberControl?.hasError('required')"
          >Required</mat-error
        >
        <mat-error *ngIf="registrationNumberControl?.hasError('pattern')"
          >Invalid character</mat-error
        >
        <mat-error *ngIf="registrationNumberControl?.hasError('maxlength')"
          >Too many characters</mat-error
        >
      </mat-form-field>
    </div>

    <!-- DATE OF INCORPORATION -->
    <div fxLayout="column" class="field">
      <label for="incorporationDate">Date of Incorporation</label>
      <mat-form-field appearance="outline">
        <input
          matInput
          [matDatepicker]="picker"
          name="incorporationDate"
          formControlName="incorporationDate"
          readonly
          [max]="today"
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <mat-error *ngIf="incorporationDateControl?.hasError('required')"
          >Required</mat-error
        >
      </mat-form-field>
    </div>

    <!-- COUNTRY OF INCORPORATION -->
    <div formGroupName="incorporationAddress" fxLayout="column" class="field">
      <label>Country of Incorporation</label>
      <mat-form-field appearance="outline">
        <mat-select
          placeholder="Please select"
          name="countryId"
          formControlName="countryId"
        >
          <mat-option
            *ngFor="let country of countries"
            [value]="country.id"
            [attr.data-testid]="
              'country-of-incorporation-' + country.code.toLowerCase()
            "
            >{{ country.name | titlecase }}</mat-option
          >
        </mat-select>
        <mat-error *ngIf="incorporationCountryIdControl?.hasError('required')"
          >Required</mat-error
        >
      </mat-form-field>
    </div>

    <!-- TAX IDENTIFICATION NUMBER -->
    <div fxLayout="column" class="field">
      <label for="taxNumber">Tax Identification Number</label>
      <mat-form-field appearance="outline">
        <input matInput name="taxNumber" formControlName="taxNumber" />
        <mat-error *ngIf="taxNumberControl?.hasError('required')"
          >Required</mat-error
        >
        <mat-error *ngIf="taxNumberControl?.hasError('pattern')"
          >Invalid character</mat-error
        >
        <mat-error *ngIf="taxNumberControl?.hasError('maxlength')"
          >Too many characters</mat-error
        >
      </mat-form-field>
      <mat-checkbox
        formControlName="taxNumberNotAvailable"
        color="primary"
        class="checkbox"
        >Tax Identification Number not available</mat-checkbox
      >
    </div>

    <!-- VAT NUMBER -->
    <div fxLayout="column" class="field">
      <label for="vatNumber">VAT Number</label>
      <mat-form-field appearance="outline">
        <input matInput name="vatNumber" formControlName="vatNumber" />
        <mat-error *ngIf="vatNumberControl?.hasError('required')"
          >Required</mat-error
        >
        <mat-error *ngIf="vatNumberControl?.hasError('pattern')"
          >Invalid character</mat-error
        >
        <mat-error *ngIf="vatNumberControl?.hasError('maxlength')"
          >Too many characters</mat-error
        >
      </mat-form-field>
      <mat-checkbox
        formControlName="vatNumberNotAvailable"
        color="primary"
        class="checkbox"
        >VAT Number not available</mat-checkbox
      >
    </div>

    <div
      fxLayout="column"
      class="field"
      *ngIf="taxNumberControl?.value !== NA || vatNumberControl?.value !== NA"
    >
      <label for="taxAndVatCountry">Country of Tax Residency/VAT Number</label>
      <mat-form-field appearance="outline">
        <mat-select
          placeholder="Please select"
          name="taxAndVatCountry"
          formControlName="taxAndVatCountry"
        >
          <mat-option *ngFor="let country of countries" [value]="country.id">{{
            country.name | titlecase
          }}</mat-option>
        </mat-select>
        <mat-error
          data-testid="validation-message-box"
          *ngIf="taxAndVatCountryControl?.hasError('required')"
          >Required</mat-error
        >
      </mat-form-field>
    </div>

    <!-- COMPANY URLS -->
    <div fxLayout="column" class="field">
      <label for="companyUrls">Company URLs (if applicable)</label>
      <mat-form-field appearance="outline">
        <textarea
          rows="3"
          #urlInput
          maxlength="200"
          matInput
          name="companyUrls"
          formControlName="companyUrls"
        ></textarea>
        <mat-error *ngIf="companyUrlsControl?.hasError('required')"
          >Required</mat-error
        >
        <mat-error *ngIf="companyUrlsControl?.hasError('pattern')"
          >Invalid character</mat-error
        >
        <mat-error *ngIf="companyUrlsControl?.hasError('maxlength')"
          >Too many characters</mat-error
        >
      </mat-form-field>
      <div class="counter">
        <label
          >{{ urlInput.value.length }} /
          {{ urlInput.getAttribute('maxlength') }}</label
        >
      </div>
    </div>

    <h2 class="operating-address-title">
      <span [matTooltip]="tooltips.registeredAddressTooltip"
        >Registered Address</span
      >
      (required)
    </h2>
    <ng-container formGroupName="incorporationAddress">
      <!-- BUSINESS ADDRESS -->
      <div fxLayout="column" class="field">
        <label style="margin-top: -15px">No PO Boxes</label>
        <label for="streetAddress"
          >Registered address (Street, building number, place)</label
        >
        <mat-form-field appearance="outline">
          <input
            matInput
            name="streetAddress"
            formControlName="streetAddress"
          />
          <mat-error
            *ngIf="incorporationStreetAddressControl?.hasError('required')"
            >Required</mat-error
          >
          <mat-error
            *ngIf="incorporationStreetAddressControl?.hasError('pattern')"
            >Invalid character</mat-error
          >
          <mat-error
            *ngIf="incorporationStreetAddressControl?.hasError('maxlength')"
            >Too many characters</mat-error
          >
        </mat-form-field>
      </div>

      <!-- BUSINESS ADDRESS 2ND LINE -->
      <div fxLayout="column" class="field">
        <label for="additionalStreetAddress"
          >Registered address 2nd line (optional)</label
        >
        <mat-form-field appearance="outline">
          <input
            matInput
            name="additionalStreetAddress"
            formControlName="additionalStreetAddress"
          />
          <mat-error
            *ngIf="
              incorporationAdditionalStreetAddressControl?.hasError('pattern')
            "
            >Invalid character</mat-error
          >
          <mat-error
            *ngIf="
              incorporationAdditionalStreetAddressControl?.hasError('maxlength')
            "
            >Too many characters</mat-error
          >
        </mat-form-field>
      </div>

      <!-- ZIP/POSTAL CODE -->
      <div fxLayout="row" fxLayoutGap="35px">
        <div fxFlex="120px" fxLayout="column">
          <label for="postCode">Zip/Postal code</label>
          <mat-form-field appearance="outline">
            <input matInput name="postCode" formControlName="postCode" />
            <mat-error
              *ngIf="incorporationPostCodeControl?.hasError('required')"
              >Required</mat-error
            >
            <mat-error *ngIf="incorporationPostCodeControl?.hasError('pattern')"
              >Invalid</mat-error
            >
            <mat-error
              *ngIf="incorporationPostCodeControl?.hasError('maxlength')"
              >Too long</mat-error
            >
          </mat-form-field>
        </div>

        <!-- CITY -->
        <div fxFlex="228px" fxLayout="column" class="short">
          <label for="city">City</label>
          <mat-form-field appearance="outline">
            <input matInput name="city" formControlName="city" />
            <mat-error *ngIf="incorporationCityControl?.hasError('required')"
              >Required</mat-error
            >
            <mat-error *ngIf="incorporationCityControl?.hasError('pattern')"
              >Invalid character</mat-error
            >
            <mat-error *ngIf="incorporationCityControl?.hasError('maxlength')"
              >Too many characters</mat-error
            >
          </mat-form-field>
        </div>
      </div>
    </ng-container>

    <h2 class="operating-address-title">
      <span [matTooltip]="tooltips.operatingAddressTooltip"
        >Operating Address</span
      >
      (required)
    </h2>
    <mat-radio-group
      fxLayout="column"
      name="isSameAddress"
      formControlName="isSameAddress"
    >
      <mat-radio-button [value]="false">
        <div>
          Operating address is <strong>different</strong> from the registered
          address
        </div>
      </mat-radio-button>
      <mat-radio-button [value]="true">
        <div>
          Operating address is the <strong>same</strong> as the registered
          address
        </div>
      </mat-radio-button>
    </mat-radio-group>

    <div
      formGroupName="operatingAddress"
      *ngIf="isSameAddressControl?.value === false"
      class="operating-address-container"
    >
      <!-- OPERATING ADDRESS -->
      <div fxLayout="column" class="field">
        <label style="margin-top: -15px">No PO Boxes</label>
        <label for="streetAddress"
          >Operating address (Street, building number, place)</label
        >
        <mat-form-field appearance="outline">
          <input
            matInput
            name="streetAddress"
            formControlName="streetAddress"
          />
          <mat-error *ngIf="operatingStreetAddressControl?.hasError('required')"
            >Required</mat-error
          >
          <mat-error *ngIf="operatingStreetAddressControl?.hasError('pattern')"
            >Invalid character</mat-error
          >
          <mat-error
            *ngIf="operatingStreetAddressControl?.hasError('maxlength')"
            >Too many characters</mat-error
          >
        </mat-form-field>
      </div>

      <!-- OPERATING ADDRESS 2ND LINE (OPTIONAL) -->
      <div fxLayout="column" class="field">
        <label for="additionalStreetAddress"
          >Operating address 2nd line (optional)</label
        >
        <mat-form-field appearance="outline">
          <input
            matInput
            name="additionalStreetAddress"
            formControlName="additionalStreetAddress"
          />
          <mat-error
            *ngIf="operatingAdditionalStreetAddressControl?.hasError('pattern')"
            >Invalid character</mat-error
          >
          <mat-error
            *ngIf="
              operatingAdditionalStreetAddressControl?.hasError('maxlength')
            "
            >Too many characters</mat-error
          >
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutGap="35px">
        <!-- ZIP/POSTAL CODE -->
        <div fxFlex="120px" fxLayout="column">
          <label for="postCode">Zip/Postal code</label>
          <mat-form-field appearance="outline">
            <input matInput name="postCode" formControlName="postCode" />
            <mat-error *ngIf="operatingPostCodeControl?.hasError('required')"
              >Required</mat-error
            >
            <mat-error *ngIf="operatingPostCodeControl?.hasError('pattern')"
              >Invalid</mat-error
            >
            <mat-error *ngIf="operatingPostCodeControl?.hasError('maxlength')"
              >Too long</mat-error
            >
          </mat-form-field>
        </div>

        <!-- CITY -->
        <div fxFlex="228px" fxLayout="column" class="short">
          <label for="city">City</label>
          <mat-form-field appearance="outline">
            <input matInput name="city" formControlName="city" />
            <mat-error *ngIf="operatingCityControl?.hasError('required')"
              >Required</mat-error
            >
            <mat-error *ngIf="operatingCityControl?.hasError('pattern')"
              >Invalid character</mat-error
            >
            <mat-error *ngIf="operatingCityControl?.hasError('maxlength')"
              >Too many characters</mat-error
            >
          </mat-form-field>
        </div>
      </div>

      <!-- OPERATING ADDRESS COUNTRY -->
      <div fxLayout="column" class="field">
        <label for="countryId">Operating address country</label>
        <mat-form-field appearance="outline">
          <mat-select
            placeholder="Please select"
            name="countryId"
            formControlName="countryId"
          >
            <mat-option
              *ngFor="let country of acceptedCountries"
              [value]="country.id"
              >{{ country.name | titlecase }}</mat-option
            >
          </mat-select>
          <mat-error *ngIf="operatingCountryIdControl?.hasError('required')"
            >Required</mat-error
          >
        </mat-form-field>
      </div>
    </div>

    <h2>Business Activity (required)</h2>

    <!-- COMPANY BUSINESS ACTIVITY -->
    <div fxLayout="column" class="field">
      <label for="companyActivity">Business activity</label>
      <mat-form-field appearance="outline" class="field">
        <mat-select
          placeholder="Please select"
          fxLayout="column"
          fxLayoutGap="14px"
          name="companyActivity"
          formControlName="companyActivity"
        >
          <mat-option
            style="
              height: auto;
              white-space: normal;
              line-height: initial;
              padding: 10px;
            "
            *ngFor="let occupation of occupations"
            [value]="occupation[1]"
            [attr.data-testid]="
              'occupation-option-' +
              occupation[1].split(' ').join('-').toLowerCase()
            "
            >{{ occupation[0] }}</mat-option
          >
        </mat-select>
        <mat-error
          data-testid="validation-message-box"
          *ngIf="companyActivityControl?.hasError('required')"
          >Required</mat-error
        >
      </mat-form-field>
    </div>

    <!-- COMPANY BUSINESS ACTIVITY DESCRIPTION -->
    <div fxLayout="column" class="field">
      <label for="companyActivityDescr"
        >Please provide a brief description of the company's business
        activities</label
      >
      <mat-form-field appearance="outline">
        <textarea
          rows="3"
          #activityInput
          maxlength="1000"
          data-testid="company-activity-desc-input"
          matInput
          name="companyActivityDescr"
          formControlName="companyActivityDescr"
        ></textarea>
        <mat-error
          data-testid="validation-message-box"
          *ngIf="companyActivityDescrControl?.hasError('required')"
          >Required</mat-error
        >
        <mat-error
          data-testid="validation-message-box"
          *ngIf="companyActivityDescrControl?.hasError('pattern')"
          >Invalid character</mat-error
        >
        <mat-error
          data-testid="validation-message-box"
          *ngIf="companyActivityDescrControl?.hasError('maxlength')"
          >Too many characters</mat-error
        >
      </mat-form-field>
      <div class="counter">
        <label
          >{{ activityInput.value.length }} /
          {{ activityInput.getAttribute('maxlength') }}</label
        >
      </div>
    </div>

    <h2>Other Information (required)</h2>

    <!-- ANNUAL INCOME -->
    <div fxLayout="column" class="field">
      <label for="annualIncome"
        >Annual income of the last year's financial statement</label
      >
      <mat-form-field appearance="outline">
        <input
          placeholder="0"
          maxlength="19"
          data-testid="company-annual-income-input"
          matInput
          name="annualIncome"
          formControlName="annualIncome"
          [value]="annualIncomeControl?.value | commify"
        />
        <div matTextSuffix>EUR</div>
        <mat-error
          data-testid="validation-message-box"
          *ngIf="annualIncomeControl?.hasError('required')"
          >Required</mat-error
        >
        <mat-error
          data-testid="validation-message-box"
          *ngIf="annualIncomeControl?.hasError('pattern')"
          >Only numbers are allowed</mat-error
        >
        <mat-error
          data-testid="validation-message-box"
          *ngIf="annualIncomeControl?.hasError('maxlength')"
          >Too many characters</mat-error
        >
      </mat-form-field>
    </div>

    <!-- ANNUAL INCOME EXPECTED TO AT LEAST DOUBLE -->
    <label
      >Is your annual income expected to at least double within the next 12
      months?</label
    >
    <mat-radio-group
      fxLayout="row"
      fxLayoutGap="14px"
      name="doubleAnnualIncome"
      formControlName="doubleAnnualIncome"
    >
      <mat-radio-button [value]="true">
        <div>Yes</div>
      </mat-radio-button>
      <mat-radio-button [value]="false">
        <div>No</div>
      </mat-radio-button>
    </mat-radio-group>

    <!-- PERCENTAGE OF CASH TRANSACTIONS ON TOTAL ACTIVITIES  -->
    <div fxLayout="column" class="field">
      <label for="percentageOfCash" class="field-space"
        >Percentage of cash transactions on total activities</label
      >
      <mat-form-field appearance="outline" class="field">
        <mat-select
          placeholder="Please select"
          fxLayout="column"
          fxLayoutGap="14px"
          name="percentageOfCash"
          formControlName="percentageOfCash"
        >
          <mat-option
            *ngFor="let cashPercentage of cashPercentages"
            [value]="cashPercentage[1]"
            >{{ cashPercentage[0] }}</mat-option
          >
        </mat-select>
        <mat-error
          data-testid="validation-message-box"
          *ngIf="percentageOfCashControl?.hasError('required')"
          >Required</mat-error
        >
      </mat-form-field>
    </div>

    <!-- IS REGULATED -->
    <label for="isRegulated" class="field-space"
      >Are you regulated by any authority?</label
    >
    <mat-radio-group
      fxLayout="row"
      fxLayoutGap="14px"
      name="isRegulated"
      formControlName="isRegulated"
    >
      <mat-radio-button [value]="true">
        <div>Yes</div>
      </mat-radio-button>
      <mat-radio-button [value]="false">
        <div>No</div>
      </mat-radio-button>
    </mat-radio-group>

    <!-- REGULATORY AUTHORITY -->
    <div
      *ngIf="isRegulatedControl?.value"
      fxLayout="column"
      class="field reg-authority"
    >
      <label for="regulatoryAuthority">Please specify which authority</label>
      <mat-form-field appearance="outline">
        <textarea
          rows="3"
          #authInput
          maxlength="200"
          data-testid="company-regulatory-authority-input"
          matInput
          name="regulatoryAuthority"
          formControlName="regulatoryAuthority"
        ></textarea>
        <mat-error
          data-testid="validation-message-box"
          *ngIf="regulatoryAuthorityControl?.hasError('required')"
          >Required</mat-error
        >
        <mat-error
          data-testid="validation-message-box"
          *ngIf="regulatoryAuthorityControl?.hasError('pattern')"
          >Invalid character</mat-error
        >
        <mat-error
          data-testid="validation-message-box"
          *ngIf="regulatoryAuthorityControl?.hasError('maxlength')"
          >Too many characters</mat-error
        >
      </mat-form-field>
      <div class="counter">
        <label
          >{{ authInput.value.length }} /
          {{ authInput.getAttribute('maxlength') }}</label
        >
      </div>
    </div>

    <!-- OPERATE IN HIGH RISK COUNTRIES  -->
    <div fxLayout="column" class="field">
      <label>Do you operate in any of the following countries:</label>
      <mat-form-field appearance="outline" class="field">
        <mat-select
          placeholder="List of countries"
          fxLayout="column"
          fxLayoutGap="14px"
        >
          <mat-option
            class="disable-selection"
            *ngFor="let country of highRiskCountries"
            [value]="country"
            >{{ country }}</mat-option
          >
        </mat-select>
      </mat-form-field>
      <mat-radio-group
        class="radio-countries"
        fxLayout="row"
        fxLayoutGap="14px"
        name="operateInHighRiskCountries"
        formControlName="operateInHighRiskCountries"
      >
        <mat-radio-button [value]="true">
          <div>Yes</div>
        </mat-radio-button>
        <mat-radio-button [value]="false">
          <div>No</div>
        </mat-radio-button>
      </mat-radio-group>
    </div>

    <!-- CAN FUND FROM OWN ACCOUNT  -->
    <label
      >Are you able to initially fund your eCREDO account from an existing
      account at a bank registered and licensed in an EEA country?</label
    >
    <mat-radio-group
      fxLayout="row"
      fxLayoutGap="14px"
      name="canFundFromOwnAccount"
      formControlName="canFundFromOwnAccount"
    >
      <mat-radio-button [value]="true">
        <div>Yes</div>
      </mat-radio-button>
      <mat-radio-button [value]="false">
        <div>No</div>
      </mat-radio-button>
    </mat-radio-group>

    <!-- ARE FINANCIAL STATEMENTS AUDITED  -->
    <label
      >Do you have financial statements (no older than two years) audited by a
      registered certified public accountant/auditor?</label
    >
    <mat-radio-group
      fxLayout="row"
      fxLayoutGap="14px"
      name="isFinancialStatementAudited"
      formControlName="isFinancialStatementAudited"
    >
      <mat-radio-button [value]="true">
        <div>Yes</div>
      </mat-radio-button>
      <mat-radio-button [value]="false">
        <div>No</div>
      </mat-radio-button>
    </mat-radio-group>

    <!-- ZERO BALANCE IN LAST YEAR'S FINANCIAL STATEMENTS -->
    <label
      >Do your last year's financial statements contain zero balance in "Total
      assets" or "Total equities and liabilities" in the balance sheet?</label
    >
    <mat-radio-group
      fxLayout="row"
      fxLayoutGap="14px"
      name="zeroBalanceStatement"
      formControlName="zeroBalanceStatement"
    >
      <mat-radio-button [value]="true">
        <div>Yes</div>
      </mat-radio-button>
      <mat-radio-button [value]="false">
        <div>No</div>
      </mat-radio-button>
    </mat-radio-group>

    <h2>Account Information (required)</h2>
    <!-- ACCOUNT OPENING PURPOSE -->
    <div fxLayout="column" class="field">
      <label for="accountPurpose">Account opening purpose</label>
      <mat-form-field appearance="outline">
        <mat-select
          placeholder="Please select"
          name="accountPurpose"
          formControlName="accountPurpose"
          multiple
        >
          <mat-option
            style="height: 50px; white-space: normal; line-height: initial"
            *ngFor="let accountPurpose of accountPurposes"
            [value]="accountPurpose[0]"
            >{{ accountPurpose[0] }}</mat-option
          >
        </mat-select>
        <mat-error
          data-testid="validation-message-box"
          *ngIf="accountPurposeControl?.hasError('required')"
          >Required</mat-error
        >
      </mat-form-field>
    </div>

    <!-- EXPECTED MONTHLY LOADING -->
    <div fxLayout="column" class="field">
      <label for="monthlyLoading">Expected monthly loading</label>
      <mat-form-field appearance="outline">
        <input
          placeholder="0"
          maxlength="19"
          data-testid="company-monthly-loading-input"
          matInput
          name="monthlyLoading"
          formControlName="monthlyLoading"
          [value]="monthlyLoadingControl?.value | commify"
        />
        <div matTextSuffix>EUR</div>
        <mat-error
          data-testid="validation-message-box"
          *ngIf="monthlyLoadingControl?.hasError('required')"
          >Required</mat-error
        >
        <mat-error
          data-testid="validation-message-box"
          *ngIf="monthlyLoadingControl?.hasError('pattern')"
          >Only numbers are allowed</mat-error
        >
        <mat-error
          data-testid="validation-message-box"
          *ngIf="monthlyLoadingControl?.hasError('maxlength')"
          >Too many characters</mat-error
        >
      </mat-form-field>
    </div>

    <!-- EXPECTED NUMBER OF PLASTIC CARDS -->
    <div fxLayout="column" class="field">
      <label for="expectedNumOfPhysicalCards"
        >Expected number of physical cards required</label
      >
      <mat-form-field appearance="outline" class="field">
        <mat-select
          placeholder="Please select"
          fxLayout="column"
          fxLayoutGap="14px"
          name="expectedNumOfPhysicalCards"
          formControlName="expectedNumOfPhysicalCards"
        >
          <mat-option
            *ngFor="let numOfPhysicalCards of [1, 2, 3, 4, 5, 6, 7, 8, 9]"
            [value]="numOfPhysicalCards"
            >{{ numOfPhysicalCards }}</mat-option
          >
        </mat-select>
        <mat-error
          data-testid="validation-message-box"
          *ngIf="expectedNumOfPhysicalCardsControl?.hasError('required')"
          >Required</mat-error
        >
      </mat-form-field>
    </div>

    <h2>Additional phone number (optional)</h2>
    <!-- ADDITIONAL PHONE NUMBER (OPTIONAL) -->
    <div fxLayout="column" class="field phone-number short">
      <label for="additionalPhoneNumber">Phone number</label>
      <ngx-intl-tel-input
        [enablePlaceholder]="false"
        [searchCountryFlag]="true"
        [separateDialCode]="true"
        name="additionalPhoneNumber"
        formControlName="additionalPhoneNumber"
      >
      </ngx-intl-tel-input>
      <div class="mat-form-field">
        <div class="mat-form-field-appearance-outline">
          <div class="mat-form-field-wrapper" style="margin-top: 0">
            <div class="mat-form-field-subscript-wrapper">
              <mat-error
                *ngIf="
                  additionalPhoneNumberControl?.invalid &&
                  additionalPhoneNumberControl?.touched
                "
                >Invalid</mat-error
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <mat-divider></mat-divider>
  </div>

  <ng-container formArrayName="accountForm" class="content">
    <h2>Company’s Accounts</h2>
    <div class="desc">
      Please provide details about banks/payment institutions where the company
      has accounts.
    </div>
    <ng-container
      *ngFor="let element of accountFormControl.controls; let index = index"
      [formGroupName]="index"
    >
      <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start center">
        <h2>{{ index + 1 | ordinalNumber }} Bank/Payment Institution</h2>

        <mat-slide-toggle
          [checked]="element.value.isActive"
          (change)="onAccountToggleChange($event, index, element.value.id)"
        ></mat-slide-toggle>
      </div>

      <div fxLayout="column" class="field">
        <label for="name">Bank/Payment Institution Name</label>
        <mat-form-field appearance="outline">
          <input matInput name="name" formControlName="name" />
          <mat-error
            data-testid="validation-message-box"
            *ngIf="accountNameControl(index)?.hasError('required')"
            >Required</mat-error
          >
          <mat-error
            data-testid="validation-message-box"
            *ngIf="accountNameControl(index)?.hasError('pattern')"
            >Invalid character</mat-error
          >
          <mat-error
            data-testid="validation-message-box"
            *ngIf="accountNameControl(index)?.hasError('maxlength')"
            >Too many characters</mat-error
          >
        </mat-form-field>
      </div>

      <div fxLayout="column" class="field">
        <label for="country">Bank/Payment Institution Country</label>
        <mat-form-field appearance="outline">
          <mat-select
            placeholder="Please select"
            name="country"
            formControlName="country"
          >
            <mat-option
              *ngFor="let country of countries"
              [value]="country.id"
              >{{ country.name | titlecase }}</mat-option
            >
          </mat-select>
          <mat-error *ngIf="accountCountryControl(index)?.hasError('required')"
            >Required</mat-error
          >
        </mat-form-field>
      </div>
    </ng-container>

    <div class="icons">
      <button
        mat-flat-button
        color="accent"
        class="g-small"
        (click)="addNewAccount()"
      >
        Add another bank/payment institution
      </button>
    </div>
  </ng-container>

  <mat-divider class="new-form-divider"></mat-divider>

  <ng-container formArrayName="counterpartyForm" class="content">
    <h2>Company’s Counterparties</h2>
    <div class="desc">
      Please provide details of the company's main
      <span [matTooltip]="tooltips.counterpartiesTooltip">counterparties</span>
      (at least one).
    </div>
    <ng-container
      *ngFor="
        let element of counterpartyFormControl.controls;
        let index = index
      "
      [formGroupName]="index"
    >
      <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start center">
        <h2>{{ index + 1 | ordinalNumber }} Counterparty</h2>

        <mat-slide-toggle
          [checked]="element.value.isActive"
          (change)="onCounterpartyToggleChange($event, index, element.value.id)"
        ></mat-slide-toggle>
      </div>

      <div fxLayout="column" class="field">
        <label for="name">Name</label>
        <mat-form-field appearance="outline">
          <input matInput name="name" formControlName="name" />
          <mat-error
            data-testid="validation-message-box"
            *ngIf="counterpartyNameControl(index)?.hasError('required')"
            >Required</mat-error
          >
          <mat-error
            data-testid="validation-message-box"
            *ngIf="counterpartyNameControl(index)?.hasError('pattern')"
            >Invalid character</mat-error
          >
          <mat-error
            data-testid="validation-message-box"
            *ngIf="counterpartyNameControl(index)?.hasError('maxlength')"
            >Too many characters</mat-error
          >
        </mat-form-field>
      </div>

      <div fxLayout="column" class="field">
        <label for="country">Country</label>
        <mat-form-field appearance="outline">
          <mat-select
            placeholder="Please select"
            name="country"
            formControlName="country"
          >
            <mat-option
              *ngFor="let country of countries"
              [value]="country.id"
              >{{ country.name | titlecase }}</mat-option
            >
          </mat-select>
          <mat-error
            *ngIf="counterpartyCountryControl(index)?.hasError('required')"
            >Required</mat-error
          >
        </mat-form-field>
      </div>

      <div fxLayout="column" class="field">
        <label for="businessActivity">Business Activity</label>
        <mat-form-field appearance="outline">
          <mat-select
            placeholder="Please select"
            fxLayout="column"
            fxLayoutGap="14px"
            name="businessActivity"
            formControlName="businessActivity"
          >
            <mat-option
              style="
                height: auto;
                white-space: normal;
                line-height: initial;
                padding: 10px;
              "
              *ngFor="let occupation of occupations"
              [value]="occupation[1]"
              >{{ occupation[0] }}</mat-option
            >
          </mat-select>
          <mat-error
            data-testid="validation-message-box"
            *ngIf="
              counterpartyBusinessActivityControl(index)?.hasError('required')
            "
            >Required</mat-error
          >
        </mat-form-field>
      </div>

      <div fxLayout="column" class="field">
        <mat-checkbox
          name="isIncomingTransaction"
          formControlName="isIncomingTransaction"
          color="primary"
        >
          Incoming Transaction
        </mat-checkbox>
      </div>
      <div fxLayout="column" class="field">
        <mat-checkbox
          name="isOutgoingTransaction"
          formControlName="isOutgoingTransaction"
          color="primary"
        >
          Outgoing Transaction
        </mat-checkbox>
      </div>
      <div class="counterparty"></div>

      <div class="errors">
        <mat-error
          *ngIf="
            getCounterpartyControl(index)?.hasError(
              'atLeastOneCheckboxCheckedCounterparty'
            )
          "
          >At least one option is required to be selected</mat-error
        >
      </div>
    </ng-container>

    <div class="errors">
      <mat-error
        *ngIf="counterpartyFormControl.hasError('incomingTransactionRequired')"
        >At least one Counterparty with Incoming Transaction is
        required</mat-error
      >
      <mat-error
        *ngIf="counterpartyFormControl.hasError('outgoingTransactionRequired')"
        >At least one Counterparty with Outgoing Transaction is
        required</mat-error
      >
      <mat-error
        *ngIf="
          counterpartyFormControl.hasError('atLeastOneCounterpartyIsRequired')
        "
        >At least one Counterparty is required</mat-error
      >
    </div>

    <div class="icons">
      <button
        mat-flat-button
        color="accent"
        class="g-small"
        (click)="addNewCounterparty()"
      >
        Add another counterparty
      </button>
    </div>
  </ng-container>

  <mat-divider class="new-form-divider"></mat-divider>

  <ng-container formArrayName="directorForm" class="content">
    <h2>Company’s Stakeholders and Authorized Contact Person</h2>
    <div class="desc">
      Please provide information for:
      <ul>
        <li>all directors and authorized contact person</li>
        <li>
          UBO/Shareholder(s) holding ≥25%. If not applicable, please provide
          information for all UBO/Shareholder(s) holding ≥10%.
        </li>
      </ul>
    </div>
    <ng-container
      *ngFor="let element of directorFormControl.controls; let index = index"
      [formGroupName]="index"
    >
      <ng-container>
        <mat-accordion>
          <mat-expansion-panel [expanded]="true" class="mat-elevation-z0">
            <mat-expansion-panel-header class="disable_ripple">
              <mat-panel-title fxLayoutAlign="space-between center">
                <div
                  fxLayout="row"
                  fxLayoutGap="20px"
                  fxLayoutAlign="start center"
                >
                  <h2 *ngIf="!element.value.isLegalPerson">
                    {{ index + 1 | ordinalNumber }} (Natural) Person
                  </h2>
                  <h2 *ngIf="element.value.isLegalPerson">
                    {{ index + 1 | ordinalNumber }} (Legal) Person
                  </h2>

                  <mat-slide-toggle
                    class="toggle"
                    [checked]="element.value.isActive"
                    (click)="$event.stopPropagation()"
                    (change)="
                      onDirectorToggleChange($event, index, element.value.id)
                    "
                  ></mat-slide-toggle>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>

            <div *ngIf="!element.value.isLegalPerson">
              <div fxLayout="column" class="field">
                <label for="firstName">First name</label>
                <mat-form-field appearance="outline">
                  <input
                    matInput
                    name="firstName"
                    formControlName="firstName"
                  />
                  <mat-error
                    data-testid="validation-message-box"
                    *ngIf="firstNameControl(index)?.hasError('required')"
                    >Required</mat-error
                  >
                  <mat-error
                    data-testid="validation-message-box"
                    *ngIf="firstNameControl(index)?.hasError('pattern')"
                    >Invalid character</mat-error
                  >
                  <mat-error
                    data-testid="validation-message-box"
                    *ngIf="firstNameControl(index)?.hasError('maxlength')"
                    >Too many characters</mat-error
                  >
                </mat-form-field>
              </div>

              <div fxLayout="column" class="field">
                <label for="lastName">Last name</label>
                <mat-form-field appearance="outline">
                  <input matInput name="lastName" formControlName="lastName" />
                  <mat-error
                    data-testid="validation-message-box"
                    *ngIf="lastNameControl(index)?.hasError('required')"
                    >Required</mat-error
                  >
                  <mat-error
                    data-testid="validation-message-box"
                    *ngIf="lastNameControl(index)?.hasError('pattern')"
                    >Invalid character</mat-error
                  >
                  <mat-error
                    data-testid="validation-message-box"
                    *ngIf="lastNameControl(index)?.hasError('maxlength')"
                    >Too many characters</mat-error
                  >
                </mat-form-field>
              </div>

              <div fxLayout="column" class="field short">
                <label for="dateOfBirth">Date of Birth</label>
                <mat-form-field appearance="outline">
                  <input
                    matInput
                    [matDatepicker]="picker"
                    name="dateOfBirth"
                    formControlName="dateOfBirth"
                    [max]="minDob"
                    readonly
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="picker"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                  <mat-error
                    *ngIf="dateOfBirthControl(index)?.hasError('required')"
                    >Required</mat-error
                  >
                </mat-form-field>
              </div>

              <div fxLayout="column" class="field short">
                <label for="nationality">Nationality</label>
                <mat-form-field appearance="outline">
                  <mat-select
                    placeholder="Please select"
                    name="nationality"
                    formControlName="nationality"
                  >
                    <mat-option
                      *ngFor="let country of countries"
                      [value]="country.id"
                      >{{ country.nationality | titlecase }}</mat-option
                    >
                  </mat-select>
                  <mat-error
                    *ngIf="nationalityControl(index)?.hasError('required')"
                    >Required</mat-error
                  >
                </mat-form-field>
              </div>
            </div>

            <div *ngIf="element.value.isLegalPerson">
              <div fxLayout="column" class="field">
                <label for="legalPersonName">Legal person name</label>
                <mat-form-field appearance="outline">
                  <input
                    matInput
                    name="legalPersonName"
                    formControlName="legalPersonName"
                  />
                  <mat-error
                    data-testid="validation-message-box"
                    *ngIf="legalPersonNameControl(index)?.hasError('required')"
                    >Required</mat-error
                  >
                  <mat-error
                    data-testid="validation-message-box"
                    *ngIf="legalPersonNameControl(index)?.hasError('pattern')"
                    >Invalid character</mat-error
                  >
                  <mat-error
                    data-testid="validation-message-box"
                    *ngIf="legalPersonNameControl(index)?.hasError('maxlength')"
                    >Too many characters</mat-error
                  >
                </mat-form-field>
              </div>

              <div fxLayout="column" class="field">
                <label for="legalPersonRegistrationNumber"
                  >Registration number</label
                >
                <mat-form-field appearance="outline">
                  <input
                    matInput
                    name="legalPersonRegistrationNumber"
                    formControlName="legalPersonRegistrationNumber"
                  />
                  <mat-error
                    data-testid="validation-message-box"
                    *ngIf="
                      legalPersonRegistrationNumberControl(index)?.hasError(
                        'required'
                      )
                    "
                    >Required</mat-error
                  >
                  <mat-error
                    data-testid="validation-message-box"
                    *ngIf="
                      legalPersonRegistrationNumberControl(index)?.hasError(
                        'pattern'
                      )
                    "
                    >Invalid character</mat-error
                  >
                  <mat-error
                    data-testid="validation-message-box"
                    *ngIf="
                      legalPersonRegistrationNumberControl(index)?.hasError(
                        'maxlength'
                      )
                    "
                    >Too many characters</mat-error
                  >
                </mat-form-field>
              </div>

              <div fxLayout="column" class="field">
                <label for="legalPersonIncorporationDate"
                  >Date of Incorporation</label
                >
                <mat-form-field appearance="outline">
                  <input
                    matInput
                    data-testid="date-of-incorporation-input"
                    [matDatepicker]="picker"
                    name="legalPersonIncorporationDate"
                    formControlName="legalPersonIncorporationDate"
                    readonly
                    [max]="today"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="picker"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                  <mat-error
                    data-testid="validation-message-box"
                    *ngIf="
                      legalPersonIncorporationDateControl(index)?.hasError(
                        'required'
                      )
                    "
                    >Required</mat-error
                  >
                </mat-form-field>
              </div>
            </div>

            <div fxLayout="column" class="field">
              <label for="email">Email</label>
              <mat-form-field appearance="outline">
                <input matInput name="email" formControlName="email" />
                <mat-error
                  data-testid="validation-message-box"
                  *ngIf="emailControl(index)?.hasError('required')"
                  >Required</mat-error
                >
                <mat-error
                  data-testid="validation-message-box"
                  *ngIf="emailControl(index)?.hasError('email')"
                  >Invalid</mat-error
                >
                <mat-error
                  data-testid="validation-message-box"
                  *ngIf="emailControl(index)?.hasError('pattern')"
                  >Invalid</mat-error
                >
              </mat-form-field>
            </div>

            <!-- address -->
            <ng-container formGroupName="address">
              <h2 *ngIf="element.value.isLegalPerson">Registered Address</h2>
              <h2 *ngIf="!element.value.isLegalPerson">Residential Address</h2>

              <div fxLayout="column" class="field">
                <label>No PO Boxes</label>
                <label for="streetAddress"
                  >Address (Street, building number, place)</label
                >
                <mat-form-field appearance="outline">
                  <input
                    matInput
                    name="streetAddress"
                    formControlName="streetAddress"
                  />
                  <mat-error
                    *ngIf="streetAddressControl(index)?.hasError('required')"
                    >Required</mat-error
                  >
                  <mat-error
                    *ngIf="streetAddressControl(index)?.hasError('pattern')"
                    >Invalid character</mat-error
                  >
                  <mat-error
                    *ngIf="streetAddressControl(index)?.hasError('maxlength')"
                    >Too many characters</mat-error
                  >
                </mat-form-field>
              </div>

              <div fxLayout="column" class="field">
                <label for="additionalStreetAddress"
                  >Address 2nd line (optional)</label
                >
                <mat-form-field appearance="outline">
                  <input
                    matInput
                    name="additionalStreetAddress"
                    formControlName="additionalStreetAddress"
                  />
                  <mat-error
                    *ngIf="
                      additionalStreetAddressControl(index)?.hasError('pattern')
                    "
                    >Invalid character</mat-error
                  >
                  <mat-error
                    *ngIf="
                      additionalStreetAddressControl(index)?.hasError(
                        'maxlength'
                      )
                    "
                    >Too many characters</mat-error
                  >
                </mat-form-field>
              </div>

              <div fxLayout="row" fxLayoutGap="35px">
                <div fxLayout="column" class="zip">
                  <label for="postCode">Zip/Postal code</label>
                  <mat-form-field appearance="outline">
                    <input
                      matInput
                      name="postCode"
                      formControlName="postCode"
                    />
                    <mat-error
                      *ngIf="postCodeControl(index)?.hasError('required')"
                      >Required</mat-error
                    >
                    <mat-error
                      *ngIf="postCodeControl(index)?.hasError('pattern')"
                      >Invalid</mat-error
                    >
                    <mat-error
                      *ngIf="postCodeControl(index)?.hasError('maxlength')"
                      >Too long</mat-error
                    >
                  </mat-form-field>
                </div>

                <div fxLayout="column" class="city">
                  <label for="city">City</label>
                  <mat-form-field appearance="outline">
                    <input matInput name="city" formControlName="city" />
                    <mat-error *ngIf="cityControl(index)?.hasError('required')"
                      >Required</mat-error
                    >
                    <mat-error *ngIf="cityControl(index)?.hasError('pattern')"
                      >Invalid character</mat-error
                    >
                    <mat-error *ngIf="cityControl(index)?.hasError('maxlength')"
                      >Too many characters</mat-error
                    >
                  </mat-form-field>
                </div>
              </div>

              <div fxLayout="column" class="field short">
                <label for="countryId">Country</label>
                <mat-form-field appearance="outline">
                  <mat-select
                    placeholder="Please select"
                    name="countryId"
                    formControlName="countryId"
                  >
                    <mat-option
                      *ngFor="let country of countries"
                      [value]="country.id"
                      >{{ country.name | titlecase }}</mat-option
                    >
                  </mat-select>
                  <mat-error
                    *ngIf="countryIdControl(index)?.hasError('required')"
                    >Required</mat-error
                  >
                </mat-form-field>
              </div>
            </ng-container>

            <div class="field" *ngIf="!element.value.isLegalPerson">
              <mat-checkbox
                formControlName="isTempAddress"
                class="checkbox"
                color="primary"
                >The address above is my temporary residence in
                EEA</mat-checkbox
              >
            </div>

            <div *ngIf="!element.value.isLegalPerson">
              <div fxLayout="column" class="field phone-number short">
                <label for="phoneNumber">Phone number</label>
                <div class="phone-description">
                  Enter a plus sign (+) followed by your country code and phone
                  number, e.g. +357xxxxxxxx
                </div>
                <ngx-intl-tel-input
                  [enablePlaceholder]="false"
                  [searchCountryFlag]="true"
                  [separateDialCode]="false"
                  data-testid="phone-number-input"
                  name="phoneNumber"
                  formControlName="phoneNumber"
                >
                </ngx-intl-tel-input>
                <mat-error
                  data-testid="validation-message-box"
                  *ngIf="
                    phoneNumberControl(index)?.invalid &&
                    phoneNumberControl(index)?.touched
                  "
                  >Invalid</mat-error
                >
              </div>
            </div>

            <div class="header">
              <h2>Status</h2>

              <div fxLayout="column" class="roles" fxLayoutGap="20px">
                <mat-checkbox
                  *ngIf="!element.value.isLegalPerson"
                  name="isAuthorizedPerson"
                  formControlName="isAuthorizedPerson"
                  color="primary"
                >
                  Authorized contact person
                </mat-checkbox>

                <mat-checkbox
                  name="isDirector"
                  formControlName="isDirector"
                  color="primary"
                >
                  Director
                </mat-checkbox>

                <div
                  fxLayout="row"
                  fxLayoutAlign="start start"
                  class="shareholder"
                >
                  <mat-checkbox
                    name="isShareholder"
                    formControlName="isShareholder"
                    color="primary"
                  >
                    Shareholder
                  </mat-checkbox>
                  <div
                    fxLayout="row"
                    fxLayoutAlign="start start"
                    class="field shares"
                    *ngIf="isShareholderControl(index)?.value"
                  >
                    <label
                      class="shareholder-shares"
                      for="percentageOwnedByShareholder"
                      >% of shares owned</label
                    >
                    <mat-form-field appearance="outline" class="small">
                      <input
                        placeholder="0.00"
                        type="number"
                        min="10"
                        max="100"
                        step=".01"
                        matInput
                        name="percentageOwnedByShareholder"
                        formControlName="percentageOwnedByShareholder"
                      />
                      <div matTextSuffix>%</div>
                      <mat-error
                        data-testid="validation-message-box"
                        *ngIf="
                          percentageOwnedByShareholderControl(index)?.hasError(
                            'required'
                          )
                        "
                        >Required</mat-error
                      >
                      <mat-error
                        data-testid="validation-message-box"
                        *ngIf="
                          percentageOwnedByShareholderControl(index)?.hasError(
                            'pattern'
                          )
                        "
                        >Use format: 0.00</mat-error
                      >
                      <mat-error
                        data-testid="validation-message-box"
                        *ngIf="
                          percentageOwnedByShareholderControl(index)?.hasError(
                            'min'
                          )
                        "
                        >Value must be greater than or equal to 10</mat-error
                      >
                      <mat-error
                        data-testid="validation-message-box"
                        *ngIf="
                          percentageOwnedByShareholderControl(index)?.hasError(
                            'max'
                          )
                        "
                        >Value must be less than or equal to 100</mat-error
                      >
                    </mat-form-field>
                  </div>
                </div>

                <div fxLayout="row" fxLayoutAlign="start start" class="ubo">
                  <mat-checkbox
                    name="isUBO"
                    formControlName="isUBO"
                    color="primary"
                  >
                    UBO
                  </mat-checkbox>
                  <div
                    fxLayout="row"
                    class="field shares"
                    *ngIf="isUBOControl(index)?.value"
                  >
                    <label class="ubo-shares" for="percentageOwnedByUBO"
                      >% of shares owned</label
                    >
                    <mat-form-field appearance="outline" class="small">
                      <input
                        placeholder="0.00"
                        type="number"
                        min="10"
                        max="100"
                        step=".01"
                        matInput
                        name="percentageOwnedByUBO"
                        formControlName="percentageOwnedByUBO"
                      />
                      <div matTextSuffix>%</div>
                      <mat-error
                        data-testid="validation-message-box"
                        *ngIf="
                          percentageOwnedByUBOControl(index)?.hasError(
                            'required'
                          )
                        "
                        >Required</mat-error
                      >
                      <mat-error
                        data-testid="validation-message-box"
                        *ngIf="
                          percentageOwnedByUBOControl(index)?.hasError(
                            'pattern'
                          )
                        "
                        >Use format: 0.00</mat-error
                      >
                      <mat-error
                        data-testid="validation-message-box"
                        *ngIf="
                          percentageOwnedByUBOControl(index)?.hasError('min')
                        "
                        >Value must be greater than or equal to 10</mat-error
                      >
                      <mat-error
                        data-testid="validation-message-box"
                        *ngIf="
                          percentageOwnedByUBOControl(index)?.hasError('max')
                        "
                        >Value must be less than or equal to 100</mat-error
                      >
                    </mat-form-field>
                  </div>
                </div>
                <mat-error
                  *ngIf="
                    getDirectorControl(index)?.hasError(
                      'atLeastOneCheckboxChecked'
                    )
                  "
                  >At least one option is required to be selected</mat-error
                >
              </div>
            </div>

            <div *ngIf="!element.value.isLegalPerson">
              <h2>Tax Details</h2>

              <div fxLayout="column" class="field short">
                <label for="taxNumber">Tax Identification Number</label>
                <mat-form-field appearance="outline">
                  <input
                    matInput
                    name="taxNumber"
                    formControlName="taxNumber"
                  />
                  <mat-error
                    data-testid="validation-message-box"
                    *ngIf="
                      taxNumberDirectorControl(index)?.hasError('required')
                    "
                    >Required</mat-error
                  >
                  <mat-error
                    data-testid="validation-message-box"
                    *ngIf="taxNumberDirectorControl(index)?.hasError('pattern')"
                    >Invalid character</mat-error
                  >
                  <mat-error
                    data-testid="validation-message-box"
                    *ngIf="
                      taxNumberDirectorControl(index)?.hasError('maxlength')
                    "
                    >Too many characters</mat-error
                  >
                </mat-form-field>
              </div>

              <div fxLayout="column" class="field short">
                <label for="taxResidencyCountry"
                  >Country of Tax Residency (if applicable)</label
                >
                <mat-form-field appearance="outline">
                  <mat-select
                    placeholder="Please select"
                    name="taxResidencyCountry"
                    formControlName="taxResidencyCountry"
                  >
                    <mat-option
                      *ngFor="let taxResidencyCountry of countries"
                      [value]="taxResidencyCountry.id"
                      >{{ taxResidencyCountry.name | titlecase }}</mat-option
                    >
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="pep-title">
                <h2>Politically Exposed Person (PEP) Details</h2>

                <div fxLayout="column" class="field pep">
                  <label class="question"
                    >Are you (or have you been in the 12 previous months) a PEP
                    or a close family member (spouse, children, parents) or a
                    close associate (any business relations) of a PEP?</label
                  >
                  <mat-radio-group
                    fxLayout="row"
                    fxLayoutGap="14px"
                    name="isPEP{{ element.value.id }}"
                    formControlName="isPEP"
                  >
                    <mat-radio-button [value]="true">
                      <div>Yes</div>
                    </mat-radio-button>
                    <mat-radio-button [value]="false">
                      <div>No</div>
                    </mat-radio-button>
                  </mat-radio-group>
                </div>
              </div>
            </div>

            <!-- 
              Show close links (to complete or update) if:
                1. key-person has already completed his close links (hasCloseLinks) regardless of client risk and key-person position, even if he's authorized person
                OR
                2. key-person hasn't completed his close links (hasCloseLinks) and is not the only authorized contact person and client is high risk
            -->
            <div
              class="pep-title"
              *ngIf="
                hasCloseLinksControl(index)?.value !== null ||
                (hasCloseLinksControl(index)?.value === null &&
                  !isOnlyAuthorizedPerson(index) &&
                  isHighRisk)
              "
            >
              <div fxLayoutAlign="start center">
                <h2>Close links</h2>
                <!-- Warning appears when said has close links but close links not entered -->
                <button
                  *ngIf="
                    getDirectorControl(index).hasError('missingCloseLinks')
                  "
                  disableRipple
                  mat-icon-button
                  (click)="selectCompanyCloseLink(element.value.id, index)"
                  [matTooltip]="tooltips.addCloseLinksTooltip"
                >
                  <mat-icon class="warning">warning_amber</mat-icon>
                </button>
              </div>
              <div fxLayout="column" class="desc field">
                <label class="question"
                  >Do you have
                  <span [matTooltip]="tooltips.closeLinksTooltip"
                    >close links</span
                  >?</label
                >
                <mat-radio-group
                  fxLayout="row"
                  fxLayoutGap="14px"
                  name="hasCloseLinks{{ element.value.id }}"
                  formControlName="hasCloseLinks"
                >
                  <mat-radio-button [value]="true">
                    <div>Yes</div>
                  </mat-radio-button>
                  <mat-radio-button [value]="false">
                    <div>No</div>
                  </mat-radio-button>
                </mat-radio-group>
                <mat-error
                  class="close-link-error"
                  data-testid="validation-message-box"
                  *ngIf="hasCloseLinksControl(index)?.hasError('required')"
                  >Required</mat-error
                >
              </div>

              <div
                *ngIf="hasCloseLinksControl(index)?.value"
                class="close-links"
              >
                <div class="caption field">
                  Please provide details of your close links:
                </div>
                <div fxLayout="row" class="field">
                  <button
                    [disabled]="element.disabled"
                    [matTooltip]="
                      element.disabled ? '' : tooltips.enterCloseLinksTooltip
                    "
                    disableRipple
                    mat-icon-button
                    (click)="selectCompanyCloseLink(element.value.id, index)"
                  >
                    <mat-icon
                      [ngClass]="element.disabled ? 'disabled cases' : 'cases'"
                      >post_add</mat-icon
                    >
                  </button>
                </div>
              </div>
            </div>
            <!-- TODO: add here documents - proof of address and id should pass through shufti pro validation
      and documents should not be overwritten -->
            <!-- need when removing document to hide document after clicking save changes -->
            <!-- <app-director-document-form
        [isReviewed]="true"
        [isFirstTimeEDD]="false"
        [documents]="element.value.documents || []"
        [companyDirectorId]="element.value.id"
        proofOfAddressLabel="Proof of Address"
      ></app-director-document-form>

      <div fxLayout="column" *ngIf="element.value.documents?.length > 0">
        <mat-form-field>
          <mat-select
            placeholder="Please select"
            name="documentType"
            formControlName="documentType"
            (selectionChange)="
              changeDocumentType($event, index, directorFormControl)
            "
          >
            <mat-option
              *ngFor="let entry of displayedDocumentOptions | keyvalue"
              [value]="entry.key"
              >{{ entry.value }}</mat-option
            >
          </mat-select>
        </mat-form-field>

        <div
          fxLayout="column"
          *ngIf="element.value.documentType && element.value.documents"
        >
          <label for="expiryDate">Document expiry date</label>
          <mat-form-field appearance="outline">
            <input
              matInput
              [matDatepicker]="picker"
              name="expiryDate"
              formControlName="expiryDate"
              readonly
              (click)="picker.open()"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
      <ng-container
        *ngIf="element.value.documentType && element.value.documents"
      >
        <div fxLayout fxLayoutAlign="space-between">
          <app-all-file-input
            [clearFile]="clearFile"
            [fileLabel]="fileLabel(element.value.documentType)"
            (uploadedFile)="
              onUploadFirstFile($event, getDirectorControl(index))
            "
          ></app-all-file-input>
          <app-all-file-input
            *ngIf="!isPassport(element.value.documentType)"
            [clearFile]="clearFile"
            [fileLabel]="fileLabel(element.value.documentType, true)"
            (uploadedFile)="
              onUploadSecondFile($event, getDirectorControl(index))
            "
          ></app-all-file-input>
        </div>
        <app-all-file-input
          [clearFile]="clearFile"
          fileLabel="Proof of Address from the director’s residence"
          (uploadedFile)="onUploadThirdFile($event, getDirectorControl(index))"
        ></app-all-file-input>
      </ng-container> -->
          </mat-expansion-panel>
        </mat-accordion>
      </ng-container>
    </ng-container>

    <div class="errors">
      <mat-error *ngIf="directorFormControl.hasError('sharesMoreThan100')"
        >Percentages of shares owned by shareholders exceed 100%</mat-error
      >
      <mat-error *ngIf="directorFormControl.hasError('atLeastOnePosition')"
        >There should be only one Authorized contact person, and at least one
        Director, Shareholder, and UBO</mat-error
      >
    </div>

    <div fxLayout="row" class="icons">
      <button
        disableRipple
        mat-icon-button
        color="accent"
        [disabled]="isSaving"
        (click)="addNewDirector(true)"
        [matTooltip]="tooltips.addNaturalPersonTooltip"
      >
        <mat-icon class="cases">person_add_alt_1</mat-icon>
      </button>
      <button
        disableRipple
        mat-icon-button
        color="accent"
        class="legal-person"
        [disabled]="isSaving"
        (click)="addNewDirector(false)"
        [matTooltip]="tooltips.addLegalPersonTooltip"
      >
        <mat-icon class="cases">domain_add</mat-icon>
      </button>
    </div>
  </ng-container>
  <mat-divider class="new-form-divider"></mat-divider>

  <ng-container formArrayName="groupMemberForm" class="content">
    <h2>Company’s Group Members</h2>
    <div class="desc">
      Please provide all details about the companies participating in the same
      group with the company.
    </div>
    <ng-container
      *ngFor="let element of groupMemberFormControl.controls; let index = index"
      [formGroupName]="index"
    >
      <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start center">
        <h2>{{ index + 1 | ordinalNumber }} Group Member</h2>

        <mat-slide-toggle
          [checked]="element.value.isActive"
          (change)="onGroupMemberToggleChange($event, index, element.value.id)"
        ></mat-slide-toggle>
      </div>

      <div fxLayout="column" class="field">
        <label for="name">Company name</label>
        <mat-form-field appearance="outline">
          <input matInput name="name" formControlName="name" />
          <mat-error
            data-testid="validation-message-box"
            *ngIf="groupMemberNameControl(index)?.hasError('required')"
            >Required</mat-error
          >
          <mat-error
            data-testid="validation-message-box"
            *ngIf="groupMemberNameControl(index)?.hasError('pattern')"
            >Invalid character</mat-error
          >
          <mat-error
            data-testid="validation-message-box"
            *ngIf="groupMemberNameControl(index)?.hasError('maxlength')"
            >Too many characters</mat-error
          >
        </mat-form-field>
      </div>

      <div fxLayout="column" class="field">
        <label for="registrationNumber">Registration number</label>
        <mat-form-field appearance="outline">
          <input
            matInput
            name="registrationNumber"
            formControlName="registrationNumber"
          />
          <mat-error
            data-testid="validation-message-box"
            *ngIf="
              groupMemberRegistrationNumberControl(index)?.hasError('required')
            "
            >Required</mat-error
          >
          <mat-error
            data-testid="validation-message-box"
            *ngIf="
              groupMemberRegistrationNumberControl(index)?.hasError('pattern')
            "
            >Invalid character</mat-error
          >
          <mat-error
            data-testid="validation-message-box"
            *ngIf="
              groupMemberRegistrationNumberControl(index)?.hasError('maxlength')
            "
            >Too many characters</mat-error
          >
        </mat-form-field>
      </div>

      <div fxLayout="column" class="field">
        <label for="incorporationDate">Date of Incorporation</label>
        <mat-form-field appearance="outline">
          <input
            matInput
            [matDatepicker]="picker"
            name="incorporationDate"
            formControlName="incorporationDate"
            readonly
            [max]="today"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
          <mat-error
            *ngIf="
              groupMemberIncorporationDateControl(index)?.hasError('required')
            "
            >Required</mat-error
          >
        </mat-form-field>
      </div>

      <ng-container formGroupName="address">
        <h2>Registered Address</h2>

        <div fxLayout="column" class="field">
          <label>No PO Boxes</label>
          <label for="streetAddress"
            >Address (Street, building number, place)</label
          >
          <mat-form-field appearance="outline">
            <input
              matInput
              name="streetAddress"
              formControlName="streetAddress"
            />
            <mat-error
              *ngIf="
                groupMemberStreetAddressControl(index)?.hasError('required')
              "
              >Required</mat-error
            >
            <mat-error
              *ngIf="
                groupMemberStreetAddressControl(index)?.hasError('pattern')
              "
              >Invalid character</mat-error
            >
            <mat-error
              *ngIf="
                groupMemberStreetAddressControl(index)?.hasError('maxlength')
              "
              >Too many characters</mat-error
            >
          </mat-form-field>
        </div>

        <div fxLayout="column" class="field">
          <label for="additionalStreetAddress"
            >Address 2nd line (optional)</label
          >
          <mat-form-field appearance="outline">
            <input
              matInput
              name="groupMemberAdditionalStreetAddress"
              formControlName="additionalStreetAddress"
            />
            <mat-error
              *ngIf="
                groupMemberAdditionalStreetAddressControl(index)?.hasError(
                  'pattern'
                )
              "
              >Invalid character</mat-error
            >
            <mat-error
              *ngIf="
                groupMemberAdditionalStreetAddressControl(index)?.hasError(
                  'maxlength'
                )
              "
              >Too many characters</mat-error
            >
          </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutGap="35px">
          <div fxLayout="column" class="zip">
            <label for="postCode">Zip/Postal code</label>
            <mat-form-field appearance="outline">
              <input matInput name="postCode" formControlName="postCode" />
              <mat-error
                *ngIf="groupMemberPostCodeControl(index)?.hasError('required')"
                >Required</mat-error
              >
              <mat-error
                *ngIf="groupMemberPostCodeControl(index)?.hasError('pattern')"
                >Invalid</mat-error
              >
              <mat-error
                *ngIf="groupMemberPostCodeControl(index)?.hasError('maxlength')"
                >Too long</mat-error
              >
            </mat-form-field>
          </div>

          <div fxLayout="column" class="city">
            <label for="city">City</label>
            <mat-form-field appearance="outline">
              <input matInput name="city" formControlName="city" />
              <mat-error
                *ngIf="groupMemberCityControl(index)?.hasError('required')"
                >Required</mat-error
              >
              <mat-error
                *ngIf="groupMemberCityControl(index)?.hasError('pattern')"
                >Invalid character</mat-error
              >
              <mat-error
                *ngIf="groupMemberCityControl(index)?.hasError('maxlength')"
                >Too many characters</mat-error
              >
            </mat-form-field>
          </div>
        </div>

        <div fxLayout="column" class="field short">
          <label for="countryId">Country</label>
          <mat-form-field appearance="outline">
            <mat-select
              placeholder="Please select"
              name="countryId"
              formControlName="countryId"
            >
              <mat-option
                *ngFor="let country of countries"
                [value]="country.id"
                >{{ country.name | titlecase }}</mat-option
              >
            </mat-select>
            <mat-error
              *ngIf="groupMemberCountryIdControl(index)?.hasError('required')"
              >Required</mat-error
            >
          </mat-form-field>
        </div>
      </ng-container>
    </ng-container>

    <div class="icons">
      <button
        mat-flat-button
        color="accent"
        class="g-small"
        (click)="addNewGroupMember()"
      >
        Add another group member
      </button>
    </div>
  </ng-container>

  <mat-divider class="new-form-divider"></mat-divider>

  <div fxLayoutAlign="space-between center">
    <button
      mat-flat-button
      color="accent"
      [disabled]="form.pristine || form.invalid || isSaving"
      [appButtonLoader]="isSaving"
      (click)="saveRegisteredUserForm()"
    >
      Save changes
    </button>
  </div>
</ng-container>
