<div class="main-container">
  <ngx-file-drop
    *ngIf="state === TemplateUploadState.isEmpty"
    dropZoneLabel="Drop the filled template here or"
    accept=".xlsx"
    [showBrowseBtn]="true"
    [multiple]="false"
    [directory]="false"
    browseBtnLabel="browse"
    dropZoneClassName="drop-zone"
    contentClassName="content"
    browseBtnClassName="browse-button"
    (onFileDrop)="dropped($event[0])"
    data-testid="mass-payments-upload"
  ></ngx-file-drop>
  <div *ngIf="state !== TemplateUploadState.isEmpty" class="states-container">
    <div [ngSwitch]="state" class="left-icon-container">
      <mat-icon
        *ngSwitchCase="TemplateUploadState.isProcessing"
        svgIcon="file"
        class="left-icon"
      ></mat-icon>
      <mat-icon
        *ngSwitchCase="TemplateUploadState.isErroring"
        svgIcon="cancel"
        class="left-icon error"
      ></mat-icon>
      <mat-icon
        *ngSwitchCase="TemplateUploadState.isUploaded"
        svgIcon="checked"
        class="left-icon checked"
      ></mat-icon>
    </div>
    <div class="text" [class.error]="state === TemplateUploadState.isErroring">
      {{ file?.name }}
    </div>
    <button
      *ngIf="state !== TemplateUploadState.isProcessing"
      mat-icon-button
      (click)="clear()"
    >
      <mat-icon svgIcon="trash" color="accent"></mat-icon>
    </button>
  </div>
</div>
