<app-onboarding-layout-new [steps]="steps">
  <ng-container onboardingLayoutTitle>
    <ng-container [ngSwitch]="isReviewed">
      <h1 data-testid="page-title-text" *ngSwitchCase="true">
        Submitted application
      </h1>
      <h1 data-testid="page-title-text" *ngSwitchDefault>Welcome to eCREDO</h1>
    </ng-container>
    <button
      *ngIf="appCompleteForm.valid && !isEDD"
      mat-flat-button
      data-testid="submit-your-application-top-button"
      color="accent"
      (click)="submit()"
    >
      Submit your application
    </button>
  </ng-container>
  <!-- STEP 0 - for reviewed application -->
  <app-reviewed-info-new
    *ngIf="isReviewed"
    [onboardingData]="onboardingData"
  ></app-reviewed-info-new>

  <!-- STEP 0 - Complete the declaration form -->
  <app-expansion-panel-new
    [expanded]="false"
    [name]="isReviewed ? 'Declaration Form' : 'Complete the declaration form'"
    [isComplete]="step0Control.value"
    data-testid="declaration-form"
  >
    <app-onboarding-individual-declaration-form
      [onboardingData]="onboardingData"
      [isReviewed]="isReviewed"
      (isComplete)="step0Control.setValue($event)"
    ></app-onboarding-individual-declaration-form>
  </app-expansion-panel-new>

  <ng-container *ngIf="step0Control.value">
    <!-- to be displayed always -->
    <mat-card>
      <h3>
        Before starting to use this application, you have to provide the
        necessary documents. Please make sure that the documents you upload
        satisfy the following requirements:
      </h3>
      <ul>
        <li>Documents uploaded must be in a readable format.</li>
        <li>All documents should be in English or Greek.</li>
        <li>
          Documents providing proof of address must be issued within the last 6
          months and in your name. These are:
        </li>
        <ul>
          <li>
            Utility bill (e.g. electricity, water, internet, phone number)
          </li>
          <li>House insurance</li>
          <li>Municipality taxes</li>
          <li>Bank statement</li>
          <li>Affidavit</li>
          <li>Temporary Residence Permit in EEA</li>
        </ul>
        <li>
          Public documents that are issued by an EU authority, if not in
          English, will require a certified translation to English.
        </li>
        <li>
          Public documents that are issued by a non-EU authority, if not in
          English, will require a certified translation to English and to be
          apostilled.
        </li>
      </ul>
    </mat-card>

    <!-- STEP 2 - Fill in the form -->
    <app-expansion-panel-new
      [expanded]="true"
      [name]="isReviewed ? 'Form' : 'Fill in the form'"
      [isComplete]="step2Control.value"
    >
      <div data-testid="fill-in-form" [formGroup]="personalForm">
        <h2>Your identity (required)</h2>
        <!-- FIRST NAME -->
        <app-onboarding-form-item-new
          label="First name"
          [isReviewed]="isReviewed"
          [value]="onboardingData?.personalForm?.firstName"
          [field]="fieldsMap.get(OnboardingFieldId.PERSONAL_FIRST_NAME)"
        >
          <div fxLayout="column" class="field">
            <label for="firstName">First name</label>
            <mat-form-field appearance="outline">
              <input
                matInput
                name="firstName"
                data-testid="first-name-input"
                formControlName="firstName"
              />
              <mat-error
                data-testid="validation-message-box"
                *ngIf="firstNameControl?.hasError('required')"
                >Required</mat-error
              >
              <mat-error
                data-testid="validation-message-box"
                *ngIf="firstNameControl?.hasError('pattern')"
                >Invalid character</mat-error
              >
              <mat-error
                data-testid="validation-message-box"
                *ngIf="firstNameControl?.hasError('maxlength')"
                >Too many characters</mat-error
              >
              <mat-error
                data-testid="validation-message-box"
                *ngIf="firstNameControl?.hasError('jointLength')"
                >First name + Last name is too long</mat-error
              >
            </mat-form-field>
          </div>
        </app-onboarding-form-item-new>

        <!-- LAST NAME -->
        <app-onboarding-form-item-new
          label="Last name"
          [isReviewed]="isReviewed"
          [value]="onboardingData?.personalForm?.lastName"
          [field]="fieldsMap.get(OnboardingFieldId.PERSONAL_LAST_NAME)"
        >
          <div fxLayout="column" class="field">
            <label for="lastName">Last name</label>
            <mat-form-field appearance="outline">
              <input
                matInput
                name="lastName"
                data-testid="last-name-input"
                formControlName="lastName"
              />
              <mat-error
                data-testid="validation-message-box"
                *ngIf="lastNameControl?.hasError('required')"
                >Required</mat-error
              >
              <mat-error
                data-testid="validation-message-box"
                *ngIf="lastNameControl?.hasError('pattern')"
                >Invalid character</mat-error
              >
              <mat-error
                data-testid="validation-message-box"
                *ngIf="lastNameControl?.hasError('maxlength')"
                >Too many characters</mat-error
              >
              <mat-error
                data-testid="validation-message-box"
                *ngIf="lastNameControl?.hasError('jointLength')"
                >First name + Last name is too long</mat-error
              >
            </mat-form-field>
          </div>
        </app-onboarding-form-item-new>

        <!-- DATE OF BIRTH -->
        <app-onboarding-form-item-new
          label="Date of Birth"
          [isReviewed]="isReviewed"
          [value]="onboardingData?.personalForm?.dateOfBirth"
          [field]="fieldsMap.get(OnboardingFieldId.PERSONAL_DATE_OF_BIRTH)"
        >
          <div fxLayout="column" class="field short">
            <label for="dateOfBirth">Date of Birth</label>
            <mat-form-field appearance="outline">
              <input
                matInput
                [matDatepicker]="picker"
                name="dateOfBirth"
                formControlName="dateOfBirth"
                readonly
                [max]="minDob"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="picker"
                data-testid="date-of-birth-input"
              ></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
              <mat-error
                data-testid="validation-message-box"
                *ngIf="dateOfBirthControl?.hasError('required')"
                >Required</mat-error
              >
            </mat-form-field>
          </div>
        </app-onboarding-form-item-new>

        <!-- COUNTRY OF BIRTH -->
        <app-onboarding-form-item-new
          label="Country of Birth"
          [isReviewed]="isReviewed"
          [value]="
            getCountryById(
              countries,
              onboardingData?.personalForm?.countryOfBirth
            )
          "
          [field]="fieldsMap.get(OnboardingFieldId.PERSONAL_COUNTRY_OF_BIRTH)"
        >
          <div fxLayout="column" class="field short">
            <label for="countryOfBirth">Country of Birth</label>
            <mat-form-field appearance="outline">
              <mat-select
                placeholder="Please select"
                data-testid="country-of-birth-select"
                name="countryOfBirth"
                formControlName="countryOfBirth"
              >
                <mat-option
                  *ngFor="let country of countries"
                  [value]="country.id"
                  [attr.data-testid]="
                    'country-of-birth-' + country.code.toLowerCase()
                  "
                  >{{ country.name | titlecase }}</mat-option
                >
              </mat-select>
              <mat-error
                data-testid="validation-message-box"
                *ngIf="countryOfBirthControl?.hasError('required')"
                >Required</mat-error
              >
            </mat-form-field>
          </div>
        </app-onboarding-form-item-new>

        <!-- NATIONALITY -->
        <app-onboarding-form-item-new
          label="Nationality"
          [isReviewed]="isReviewed"
          [value]="
            getNationalityById(
              countries,
              onboardingData?.personalForm?.nationality
            )
          "
          [field]="fieldsMap.get(OnboardingFieldId.PERSONAL_NATIONALITY)"
        >
          <div fxLayout="column" class="field short">
            <label for="nationality">Nationality</label>
            <mat-form-field appearance="outline">
              <mat-select
                placeholder="Please select"
                data-testid="nationality-select"
                name="nationality"
                formControlName="nationality"
              >
                <mat-option
                  *ngFor="let country of countries"
                  [value]="country.id"
                  [attr.data-testid]="
                    'nationality-' + country.code.toLowerCase()
                  "
                  >{{ country.nationality | titlecase }}</mat-option
                >
              </mat-select>
              <mat-error
                data-testid="validation-message-box"
                *ngIf="nationalityControl?.hasError('required')"
                >Required</mat-error
              >
            </mat-form-field>
          </div>
        </app-onboarding-form-item-new>

        <h2>Your residential address (required)</h2>
        <ng-container formGroupName="address">
          <!-- STREET ADDRESS -->
          <app-onboarding-form-item-new
            label="Address (Street, building number, place)"
            [isReviewed]="isReviewed"
            [value]="onboardingData?.personalForm?.address?.streetAddress"
            [field]="fieldsMap.get(OnboardingFieldId.PERSONAL_ADDRESS)"
          >
            <div fxLayout="column" class="field">
              <label style="margin-top: -15px">No PO Boxes</label>
              <label for="streetAddress"
                >Address (Street, building number, place)</label
              >
              <mat-form-field appearance="outline">
                <input
                  matInput
                  data-testid="street-address-input"
                  name="streetAddress"
                  formControlName="streetAddress"
                />
                <mat-error
                  data-testid="validation-message-box"
                  *ngIf="streetAddressControl?.hasError('required')"
                  >Required</mat-error
                >
                <mat-error
                  data-testid="validation-message-box"
                  *ngIf="streetAddressControl?.hasError('pattern')"
                  >Invalid character</mat-error
                >
                <mat-error
                  data-testid="validation-message-box"
                  *ngIf="streetAddressControl?.hasError('maxlength')"
                  >Too many characters</mat-error
                >
              </mat-form-field>
            </div>
          </app-onboarding-form-item-new>

          <!-- ADDRESS 2ND LINE (OPTIONAL) -->
          <app-onboarding-form-item-new
            label="Address 2nd line (optional)"
            [isReviewed]="isReviewed"
            [value]="
              onboardingData?.personalForm?.address?.additionalStreetAddress
            "
            [field]="fieldsMap.get(OnboardingFieldId.PERSONAL_ADDRESS_2ND_LINE)"
          >
            <div fxLayout="column" class="field">
              <label for="additionalStreetAddress"
                >Address 2nd line (optional)</label
              >
              <mat-form-field appearance="outline">
                <input
                  matInput
                  data-testid="street-address-second-part-input"
                  name="additionalStreetAddress"
                  formControlName="additionalStreetAddress"
                />
                <mat-error
                  data-testid="validation-message-box"
                  *ngIf="additionalStreetAddressControl?.hasError('pattern')"
                  >Invalid character</mat-error
                >
                <mat-error
                  data-testid="validation-message-box"
                  *ngIf="additionalStreetAddressControl?.hasError('maxlength')"
                  >Too many characters</mat-error
                >
              </mat-form-field>
            </div>
          </app-onboarding-form-item-new>

          <div
            [fxLayout]="isReviewed ? 'column' : 'row'"
            [class.field]="!isReviewed"
            [fxLayoutGap]="isReviewed ? '0px' : '35px'"
            fxLayoutGap.lt-sm="9px"
          >
            <!-- ZIP/POSTAL CODE -->
            <app-onboarding-form-item-new
              label="Zip/Postal code"
              [isReviewed]="isReviewed"
              [value]="onboardingData?.personalForm?.address?.postCode"
              [field]="
                fieldsMap.get(OnboardingFieldId.PERSONAL_ZIP_POSTAL_CODE)
              "
            >
              <div fxLayout="column" class="zip" [class.field]="isReviewed">
                <label for="postCode">Zip/Postal code</label>
                <mat-form-field appearance="outline">
                  <input
                    matInput
                    name="postCode"
                    data-testid="post-code-input"
                    formControlName="postCode"
                  />
                  <mat-error
                    data-testid="validation-message-box"
                    *ngIf="postCodeControl?.hasError('required')"
                    >Required</mat-error
                  >
                  <mat-error
                    data-testid="validation-message-box"
                    *ngIf="postCodeControl?.hasError('pattern')"
                    >Invalid</mat-error
                  >
                  <mat-error
                    data-testid="validation-message-box"
                    *ngIf="postCodeControl?.hasError('maxlength')"
                    >Too long</mat-error
                  >
                </mat-form-field>
              </div>
            </app-onboarding-form-item-new>

            <!-- CITY -->
            <app-onboarding-form-item-new
              label="City"
              [isReviewed]="isReviewed"
              [value]="onboardingData?.personalForm?.address?.city"
              [field]="fieldsMap.get(OnboardingFieldId.PERSONAL_CITY)"
            >
              <div fxLayout="column" class="city" [class.field]="isReviewed">
                <label for="city">City</label>
                <mat-form-field appearance="outline">
                  <input
                    data-testid="city-input"
                    matInput
                    name="city"
                    formControlName="city"
                  />
                  <mat-error
                    data-testid="validation-message-box"
                    *ngIf="cityControl?.hasError('required')"
                    >Required</mat-error
                  >
                  <mat-error
                    data-testid="validation-message-box"
                    *ngIf="cityControl?.hasError('pattern')"
                    >Invalid character</mat-error
                  >
                  <mat-error
                    data-testid="validation-message-box"
                    *ngIf="cityControl?.hasError('maxlength')"
                    >Too many characters</mat-error
                  >
                </mat-form-field>
              </div>
            </app-onboarding-form-item-new>
          </div>

          <!-- COUNTRY -->
          <app-onboarding-form-item-new
            label="Country"
            [isReviewed]="isReviewed"
            [value]="
              getCountryById(
                countries,
                onboardingData?.personalForm?.address?.countryId
              )
            "
            [field]="fieldsMap.get(OnboardingFieldId.PERSONAL_COUNTRY)"
          >
            <div fxLayout="column" class="field short">
              <label for="countryId">Country</label>
              <mat-form-field appearance="outline">
                <mat-select
                  placeholder="Please select"
                  data-testid="country-of-residence-select"
                  name="countryId"
                  formControlName="countryId"
                >
                  <mat-option
                    *ngFor="let country of acceptedCountries"
                    [value]="country.id"
                    [attr.data-testid]="
                      'country-of-residence-' + country.code.toLowerCase()
                    "
                    >{{ country.name | titlecase }}</mat-option
                  >
                </mat-select>
                <mat-error
                  data-testid="validation-message-box"
                  *ngIf="countryIdControl?.hasError('required')"
                  >Required</mat-error
                >
              </mat-form-field>
            </div>
          </app-onboarding-form-item-new>
        </ng-container>

        <app-onboarding-form-item-new
          label="The address above is my temporary residence in EEA"
          [isReviewed]="isReviewed"
          [value]="getStringValue(onboardingData?.personalForm?.isTempAddress)"
          [field]="
            fieldsMap.get(OnboardingFieldId.PERSONAL_DATA_IS_TEMP_ADDRESS)
          "
        >
          <div class="field">
            <mat-checkbox
              formControlName="isTempAddress"
              color="primary"
              class="checkbox"
              >The address above is my temporary residence in EEA</mat-checkbox
            >
          </div>
        </app-onboarding-form-item-new>

        <h2>Additional phone number and email (optional)</h2>
        <!-- ADDITIONAL PHONE NUMBER -->
        <app-onboarding-form-item-new
          label="Phone number"
          [isReviewed]="isReviewed"
          [value]="onboardingData?.personalForm?.additionalPhoneNumber"
          [field]="
            fieldsMap.get(OnboardingFieldId.PERSONAL_ADDITIONAL_PHONE_NUMBER)
          "
        >
          <div fxLayout="column" class="field phone-number">
            <label for="additionalPhoneNumber"
              >Phone number (Select the country code and enter your phone
              number)</label
            >
            <ngx-intl-tel-input
              [enablePlaceholder]="false"
              [searchCountryFlag]="true"
              [separateDialCode]="true"
              [selectedCountryISO]="countryCode"
              name="additionalPhoneNumber"
              data-testid="optional-phone-number-input"
              formControlName="additionalPhoneNumber"
            >
            </ngx-intl-tel-input>
            <mat-error
              data-testid="validation-message-box"
              *ngIf="
                additionalPhoneNumberControl?.invalid &&
                additionalPhoneNumberControl?.touched
              "
              >Invalid</mat-error
            >
          </div>
        </app-onboarding-form-item-new>

        <!-- SECONDARY EMAIL -->
        <app-onboarding-form-item-new
          label="Secondary email"
          [isReviewed]="isReviewed"
          [value]="onboardingData?.personalForm?.additionalEmail"
          [field]="fieldsMap.get(OnboardingFieldId.PERSONAL_ADDITIONAL_EMAIL)"
        >
          <div fxLayout="column" class="field">
            <label for="additionalEmail">Secondary email</label>
            <mat-form-field appearance="outline">
              <input
                data-testid="secondary-email-input"
                matInput
                name="additionalEmail"
                formControlName="additionalEmail"
              />
              <mat-error
                data-testid="validation-message-box"
                *ngIf="additionalEmailControl?.hasError('email')"
                >Invalid</mat-error
              >
              <mat-error
                data-testid="validation-message-box"
                *ngIf="additionalEmailControl?.hasError('pattern')"
                >Invalid</mat-error
              >
            </mat-form-field>
          </div>
        </app-onboarding-form-item-new>

        <!-- QUIZ -->
        <h2>Tell us a bit about your occupation (required)</h2>
        <app-onboarding-form-item-new
          label="What is your occupation status?"
          [isReviewed]="isReviewed"
          [value]="onboardingData?.personalForm?.dataOccupationProfession"
          [field]="
            fieldsMap.get(OnboardingFieldId.PERSONAL_DATA_OCCUPATION_PROFESSION)
          "
        >
          <div fxLayout="column" class="field">
            <label for="dataOccupationProfession"
              >What is your occupation status?</label
            >
            <mat-form-field appearance="outline">
              <mat-select
                placeholder="Please select"
                fxLayout="column"
                fxLayoutGap="14px"
                name="dataOccupationProfession"
                formControlName="dataOccupationProfession"
                data-testid="occupation-status-select"
              >
                <mat-option
                  *ngFor="let occupationProfession of occupationProfessions"
                  [value]="occupationProfession[1]"
                  data-testid="occupation-status-option"
                  >{{ occupationProfession[0] }}</mat-option
                >
              </mat-select>
              <mat-error
                data-testid="validation-message-box"
                *ngIf="dataOccupationProfessionControl?.hasError('required')"
                >Required</mat-error
              >
            </mat-form-field>
          </div>
        </app-onboarding-form-item-new>

        <app-onboarding-form-item-new
          label="What industry are you involved in?"
          [isReviewed]="isReviewed"
          [value]="onboardingData?.personalForm?.dataOccupation"
          [field]="fieldsMap.get(OnboardingFieldId.PERSONAL_DATA_OCCUPATION)"
        >
          <div fxLayout="column" class="field">
            <label for="dataOccupation"
              >What industry are you involved in?</label
            >
            <mat-form-field appearance="outline">
              <mat-select
                placeholder="Please select"
                fxLayout="column"
                fxLayoutGap="14px"
                name="dataOccupation"
                formControlName="dataOccupation"
                data-testid="industry-involved-select"
              >
                <mat-option
                  style="
                    height: auto;
                    white-space: normal;
                    line-height: initial;
                    padding: 10px;
                  "
                  *ngFor="let occupation of occupations"
                  [value]="occupation[1]"
                  data-testid="industry-involved-option"
                  >{{ occupation[0] }}</mat-option
                >
              </mat-select>
              <mat-error
                data-testid="validation-message-box"
                *ngIf="dataOccupationControl?.hasError('required')"
                >Required</mat-error
              >
            </mat-form-field>
          </div>
        </app-onboarding-form-item-new>

        <h2>Account information (required)</h2>
        <app-onboarding-form-item-new
          label="What is the main purpose of your account?"
          [isReviewed]="isReviewed"
          [value]="onboardingData?.personalForm?.dataCardPurpose"
          [field]="fieldsMap.get(OnboardingFieldId.PERSONAL_DATA_CARD_PURPOSE)"
        >
          <div fxLayout="column" class="field">
            <label for="dataCardPurpose"
              >What is the main purpose of your account?</label
            >
            <mat-form-field appearance="outline">
              <mat-select
                placeholder="Please select"
                name="dataCardPurpose"
                formControlName="dataCardPurpose"
                multiple
                data-testid="account-purpose-select"
              >
                <mat-option
                  *ngFor="let cardPurpose of cardPurposes"
                  [value]="cardPurpose[0]"
                  data-testid="account-purpose-option"
                  >{{ cardPurpose[0] }}</mat-option
                >
              </mat-select>
              <mat-error
                data-testid="validation-message-box"
                *ngIf="dataCardPurposeControl?.hasError('required')"
                >Required</mat-error
              >
            </mat-form-field>
          </div>
        </app-onboarding-form-item-new>

        <!-- MONTHLY INCOME -->
        <app-onboarding-form-item-new
          label="What is your monthly income?"
          [isReviewed]="isReviewed"
          [value]="
            getMonthlyIncome(onboardingData?.personalForm?.dataMonthlyIncome)
          "
          [field]="
            fieldsMap.get(OnboardingFieldId.PERSONAL_DATA_MONTHLY_INCOME)
          "
        >
          <div fxLayout="column" class="field">
            <label for="dataMonthlyIncome">What is your monthly income?</label>
            <mat-form-field appearance="outline">
              <mat-select
                placeholder="Please select"
                fxLayout="column"
                fxLayoutGap="14px"
                name="dataMonthlyIncome"
                formControlName="dataMonthlyIncome"
                data-testid="monthly-income-select"
              >
                <mat-option
                  *ngFor="let monthlyIncome of monthlyIncomes"
                  [value]="monthlyIncome[1]"
                  data-testid="monthly-income-option"
                  >{{ monthlyIncome[0] }}</mat-option
                >
              </mat-select>
              <mat-error
                data-testid="validation-message-box"
                *ngIf="dataMonthlyIncomeControl?.hasError('required')"
                >Required</mat-error
              >
            </mat-form-field>
          </div>
        </app-onboarding-form-item-new>

        <!-- MONTHLY SPEND -->
        <app-onboarding-form-item-new
          label="What is your monthly spend?"
          [isReviewed]="isReviewed"
          [value]="
            getMonthlySpend(onboardingData?.personalForm?.dataMonthlySpend)
          "
          [field]="fieldsMap.get(OnboardingFieldId.PERSONAL_DATA_MONTHLY_SPEND)"
        >
          <div fxLayout="column" class="field">
            <label for="dataMonthlySpend">What is your monthly spend?</label>
            <mat-form-field appearance="outline">
              <mat-select
                placeholder="Please select"
                fxLayout="column"
                fxLayoutGap="14px"
                name="dataMonthlySpend"
                formControlName="dataMonthlySpend"
                data-testid="monthly-spend-select"
              >
                <mat-option
                  *ngFor="let monthlySpend of monthlySpends"
                  [value]="monthlySpend[1]"
                  data-testid="monthly-spend-option"
                  >{{ monthlySpend[0] }}</mat-option
                >
              </mat-select>
              <mat-error
                data-testid="validation-message-box"
                *ngIf="dataMonthlySpendControl?.hasError('required')"
                >Required</mat-error
              >
            </mat-form-field>
          </div>
        </app-onboarding-form-item-new>

        <!-- PEP -->
        <h2>Politically Exposed Person (PEP) Details (required)</h2>
        <app-onboarding-form-item-new
          label="Are you (or have you been in the 12 previous months) a PEP or a close family member (spouse, children, parents) or a close associate (any business relations) of a PEP?"
          [isReviewed]="isReviewed"
          [value]="getStringValue(onboardingData?.personalForm?.isPEP)"
          [field]="fieldsMap.get(OnboardingFieldId.PERSONAL_DATA_IS_PEP)"
        >
          <div fxLayout="column" class="field">
            <label class="question">
              Are you (or have you been in the 12 previous months) a PEP or a
              close family member (spouse, children, parents) or a close
              associate (any business relations) of a PEP?
            </label>
            <mat-radio-group
              fxLayout="row"
              fxLayoutGap="14px"
              name="isPEP"
              formControlName="isPEP"
            >
              <mat-radio-button [value]="true" data-testid="is-pep">
                <div>Yes</div>
              </mat-radio-button>
              <mat-radio-button [value]="false">
                <div>No</div>
              </mat-radio-button>
            </mat-radio-group>
          </div>
        </app-onboarding-form-item-new>

        <h2>Tax/VAT Details</h2>
        <!-- TAX IDENTIFICATION NUMBER -->
        <app-onboarding-form-item-new
          label="Tax Identification Number"
          [isReviewed]="isReviewed"
          [value]="onboardingData?.personalForm?.dataTaxNumber"
          [field]="fieldsMap.get(OnboardingFieldId.PERSONAL_DATA_TAX_NUMBER)"
        >
          <div fxLayout="column" fxLayoutAlign="start start">
            <div fxLayout="column" class="field">
              <label for="dataTaxNumber">Tax Identification Number</label>
              <mat-form-field appearance="outline">
                <input
                  data-testid="tax-number-input"
                  matInput
                  name="dataTaxNumber"
                  formControlName="dataTaxNumber"
                />
                <mat-error
                  data-testid="validation-message-box"
                  *ngIf="dataTaxNumberControl?.hasError('required')"
                  >Required</mat-error
                >
                <mat-error
                  data-testid="validation-message-box"
                  *ngIf="dataTaxNumberControl?.hasError('pattern')"
                  >Invalid character</mat-error
                >
                <mat-error
                  data-testid="validation-message-box"
                  *ngIf="dataTaxNumberControl?.hasError('maxlength')"
                  >Too many characters</mat-error
                >
              </mat-form-field>
            </div>
            <mat-checkbox
              formControlName="dataTaxNumberNotAvailable"
              color="primary"
              class="checkbox"
              >Tax Identification Number not available</mat-checkbox
            >
          </div>
        </app-onboarding-form-item-new>

        <!-- VAT NUMBER -->
        <app-onboarding-form-item-new
          label="VAT Number"
          [isReviewed]="isReviewed"
          [value]="onboardingData?.personalForm?.dataVatNumber"
          [field]="fieldsMap.get(OnboardingFieldId.PERSONAL_DATA_VAT_NUMBER)"
        >
          <div fxLayout="column" class="field">
            <label for="dataVatNumber">VAT Number</label>
            <mat-form-field appearance="outline">
              <input
                data-testid="vat-number-input"
                matInput
                name="dataVatNumber"
                formControlName="dataVatNumber"
              />
              <mat-error
                data-testid="validation-message-box"
                *ngIf="dataVatNumberControl?.hasError('required')"
                >Required</mat-error
              >
              <mat-error
                data-testid="validation-message-box"
                *ngIf="dataVatNumberControl?.hasError('pattern')"
                >Invalid character</mat-error
              >
              <mat-error
                data-testid="validation-message-box"
                *ngIf="dataVatNumberControl?.hasError('maxlength')"
                >Too many characters</mat-error
              >
            </mat-form-field>
          </div>
          <mat-checkbox
            formControlName="dataVatNumberNotAvailable"
            color="primary"
            class="checkbox"
            >VAT Number not available</mat-checkbox
          >
        </app-onboarding-form-item-new>

        <!-- COUNTRY OF TAX RESIDENCY/VAT NUMBER -->
        <app-onboarding-form-item-new
          label="Country of Tax Residency/VAT Number"
          [isReviewed]="isReviewed"
          [value]="
            getCountryById(
              countries,
              onboardingData?.personalForm?.dataTaxAndVatCountry
            )
          "
          [field]="
            fieldsMap.get(OnboardingFieldId.PERSONAL_DATA_TAX_AND_VAT_COUNTRY)
          "
          *ngIf="
            dataTaxNumberControl?.value !== NA ||
            dataVatNumberControl?.value !== NA
          "
        >
          <div fxLayout="column" class="field short">
            <label for="dataTaxAndVatCountry"
              >Country of Tax Residency/VAT Number</label
            >
            <mat-form-field appearance="outline">
              <mat-select
                placeholder="Please select"
                name="dataTaxAndVatCountry"
                formControlName="dataTaxAndVatCountry"
                data-testid="country-of-tax-vat-number-select"
              >
                <mat-option
                  *ngFor="let dataTaxAndVatCountry of countries"
                  [value]="dataTaxAndVatCountry.id"
                  [attr.data-testid]="
                    'country-of-tax-vat-number-' +
                    dataTaxAndVatCountry.code.toLowerCase()
                  "
                  >{{ dataTaxAndVatCountry.name | titlecase }}</mat-option
                >
              </mat-select>
              <mat-error
                data-testid="validation-message-box"
                *ngIf="dataTaxAndVatCountryControl?.hasError('required')"
                >Required</mat-error
              >
            </mat-form-field>
          </div>
        </app-onboarding-form-item-new>

        <!-- EXTRA QUESTIONS should appear when (program is JADE and form is EDD/EDD_SUBMITTED) or (program is EMERALD) -->

        <div *ngIf="showExtraQuestions">
          <mat-divider></mat-divider>
          <h2 class="questions">Additional financial information (required)</h2>

          <!-- SOURCE OF WEALTH -->
          <app-onboarding-form-item-new
            label="Source of Wealth"
            [isReviewed]="isReviewed && program === Program.EMERALD"
            [value]="onboardingData?.personalForm?.dataSourceOfWealth"
            [field]="
              fieldsMap.get(OnboardingFieldId.PERSONAL_DATA_SOURCE_OF_WEALTH)
            "
          >
            <div fxLayout="column" class="field">
              <h2>Source of Wealth</h2>
              <mat-form-field appearance="outline">
                <mat-select
                  placeholder="Please select"
                  name="dataSourceOfWealth"
                  formControlName="dataSourceOfWealth"
                  multiple
                  data-testid="source-of-wealth-select"
                >
                  <mat-option
                    *ngFor="let sourceOfWealth of sourcesOfWealth"
                    [value]="sourceOfWealth[0]"
                    data-testid="source-of-wealth-option"
                    >{{ sourceOfWealth[0] }}</mat-option
                  >
                </mat-select>
                <mat-error
                  data-testid="validation-message-box"
                  *ngIf="dataSourceOfWealthControl?.hasError('required')"
                  >Required</mat-error
                >
              </mat-form-field>
            </div>
          </app-onboarding-form-item-new>

          <!-- COUNTRY OF SOURCE OF WEALTH -->
          <app-onboarding-form-item-new
            label="Country of Origin (Source of Wealth)"
            [isReviewed]="isReviewed && program === Program.EMERALD"
            [value]="
              getCountryById(
                countries,
                onboardingData?.personalForm?.countryOfSourceOfWealth
              )
            "
            [field]="
              fieldsMap.get(
                OnboardingFieldId.PERSONAL_DATA_COUNTRY_OF_SOURCE_OF_WEALTH
              )
            "
          >
            <div fxLayout="column" class="field short">
              <label for="countryOfSourceOfWealth"
                >Country of Origin (Source of Wealth)</label
              >
              <mat-form-field appearance="outline">
                <mat-select
                  placeholder="Please select"
                  data-testid="country-of-wealth-select"
                  name="countryOfSourceOfWealth"
                  formControlName="countryOfSourceOfWealth"
                >
                  <mat-option
                    *ngFor="let country of countries"
                    [value]="country.id"
                    [attr.data-testid]="
                      'country-of-wealth-' + country.code.toLowerCase()
                    "
                    >{{ country.name | titlecase }}</mat-option
                  >
                </mat-select>
                <mat-error
                  data-testid="validation-message-box"
                  *ngIf="countryOfSourceOfWealthControl?.hasError('required')"
                  >Required</mat-error
                >
              </mat-form-field>
            </div>
          </app-onboarding-form-item-new>

          <!-- SOURCE OF INCOME -->
          <app-onboarding-form-item-new
            label="Source of Income"
            [isReviewed]="isReviewed && program === Program.EMERALD"
            [value]="onboardingData?.personalForm?.dataSourceOfIncome"
            [field]="
              fieldsMap.get(OnboardingFieldId.PERSONAL_DATA_SOURCE_OF_INCOME)
            "
          >
            <div fxLayout="column" class="field">
              <h2>Source of Income</h2>
              <mat-form-field appearance="outline">
                <mat-select
                  placeholder="Please select"
                  name="dataSourceOfIncome"
                  formControlName="dataSourceOfIncome"
                  multiple
                  data-testid="source-of-income-select"
                >
                  <mat-option
                    *ngFor="let sourceOfIncome of sourcesOfIncome"
                    [value]="sourceOfIncome[0]"
                    data-testid="source-of-income-option"
                    >{{ sourceOfIncome[0] }}</mat-option
                  >
                </mat-select>
                <mat-error
                  data-testid="validation-message-box"
                  *ngIf="dataSourceOfIncomeControl?.hasError('required')"
                  >Required</mat-error
                >
              </mat-form-field>
            </div>
          </app-onboarding-form-item-new>

          <!-- COUNTRY OF SOURCE OF INCOME -->
          <app-onboarding-form-item-new
            label="Country of Origin (Source of Income)"
            [isReviewed]="isReviewed && program === Program.EMERALD"
            [value]="
              getCountryById(
                countries,
                onboardingData?.personalForm?.countryOfSourceOfIncome
              )
            "
            [field]="
              fieldsMap.get(
                OnboardingFieldId.PERSONAL_DATA_COUNTRY_OF_SOURCE_OF_INCOME
              )
            "
          >
            <div fxLayout="column" class="field short">
              <label for="countryOfSourceOfIncome"
                >Country of Origin (Source of Income)</label
              >
              <mat-form-field appearance="outline">
                <mat-select
                  placeholder="Please select"
                  data-testid="country-of-income-select"
                  name="countryOfSourceOfIncome"
                  formControlName="countryOfSourceOfIncome"
                >
                  <mat-option
                    *ngFor="let country of countries"
                    [value]="country.id"
                    [attr.data-testid]="
                      'country-of-income-' + country.code.toLowerCase()
                    "
                    >{{ country.name | titlecase }}</mat-option
                  >
                </mat-select>
                <mat-error
                  data-testid="validation-message-box"
                  *ngIf="countryOfSourceOfIncomeControl?.hasError('required')"
                  >Required</mat-error
                >
              </mat-form-field>
            </div>
          </app-onboarding-form-item-new>

          <!-- SIZE OF WEALTH -->
          <app-onboarding-form-item-new
            label="Size of Wealth"
            [isReviewed]="isReviewed && program === Program.EMERALD"
            [value]="
              getSizeOfWealth(onboardingData?.personalForm?.dataSizeOfWealth)
            "
            [field]="
              fieldsMap.get(OnboardingFieldId.PERSONAL_DATA_SIZE_OF_WEALTH)
            "
          >
            <div fxLayout="column" class="field">
              <h2>Size of Wealth</h2>
              <mat-form-field appearance="outline">
                <mat-select
                  placeholder="Please select"
                  fxLayout="column"
                  fxLayoutGap="14px"
                  name="dataSizeOfWealth"
                  formControlName="dataSizeOfWealth"
                  data-testid="size-of-wealth-select"
                >
                  <mat-option
                    *ngFor="let sizeOfWealth of sizesOfWealth"
                    [value]="sizeOfWealth[0]"
                    data-testid="size-of-wealth-option"
                    >{{ sizeOfWealth[0] }}</mat-option
                  >
                </mat-select>
                <mat-error
                  data-testid="validation-message-box"
                  *ngIf="dataSizeOfWealthControl?.hasError('required')"
                  >Required</mat-error
                >
              </mat-form-field>
            </div>
          </app-onboarding-form-item-new>
        </div>

        <mat-divider></mat-divider>
        <!-- EMAIL OF YOUR REFEREE (OPTIONAL) -->
        <app-onboarding-form-item-new
          label="Email of your referee (optional)"
          [isReviewed]="isReviewed"
          [value]="onboardingData?.personalForm?.referee"
          [field]="fieldsMap.get(OnboardingFieldId.PERSONAL_EMAIL_OF_REFEREE)"
        >
          <div fxLayout="column" class="field">
            <label for="referee">Email of your referee (optional)</label>
            <mat-form-field appearance="outline">
              <input
                data-testid="email-of-your-referee"
                matInput
                name="referee"
                formControlName="referee"
              />
              <mat-error
                data-testid="validation-message-box"
                *ngIf="refereeControl?.hasError('email')"
                >Invalid</mat-error
              >
            </mat-form-field>
          </div>
        </app-onboarding-form-item-new>
        <!-- HEARD FROM -->
        <app-onboarding-form-item-new
          label="How did you hear about us? (optional)"
          [isReviewed]="isReviewed"
          [value]="onboardingData?.personalForm?.heardFrom"
          [field]="fieldsMap.get(OnboardingFieldId.PERSONAL_HEARD_FROM)"
        >
          <div fxLayout="column" class="field">
            <label for="heardFrom">How did you hear about us? (optional)</label>
            <mat-form-field appearance="outline">
              <input
                data-testid="heard-from-input"
                matInput
                name="heardFrom"
                formControlName="heardFrom"
              />
              <mat-error
                data-testid="validation-message-box"
                *ngIf="heardFromControl?.hasError('pattern')"
                >Invalid character</mat-error
              >
              <mat-error
                data-testid="validation-message-box"
                *ngIf="heardFromControl?.hasError('maxlength')"
                >Too many characters</mat-error
              >
            </mat-form-field>
          </div>
        </app-onboarding-form-item-new>

        <mat-divider></mat-divider>

        <div fxLayoutAlign="space-between center">
          <button
            mat-flat-button
            data-testid="save-form-button"
            color="accent"
            [disabled]="
              personalForm.invalid || isSavingStep2 || personalForm.pristine
            "
            [appButtonLoader]="isSavingStep2"
            (click)="savePersonalForm()"
          >
            Save
          </button>
          <ng-container [ngSwitch]="step2Control.valid">
            <div *ngSwitchCase="true" class="status complete">Complete</div>
            <div *ngSwitchCase="false" class="status">Incomplete</div>
          </ng-container>
        </div>
      </div>
    </app-expansion-panel-new>

    <!-- STEP 1 - Upload the documents -->
    <app-expansion-panel-new
      *ngIf="step2Control.value"
      [expanded]="isEDD ? false : true"
      [name]="isReviewed ? 'Uploaded documents' : 'Upload the documents'"
      [isComplete]="step1Control.value"
      data-testid="upload-documents-form"
    >
      <div>
        <div *ngIf="!verificationAccepted">
          Please ensure that you have your identity and proof of address
          documents ready, and that your camera is enabled.<br />
          <button
            mat-flat-button
            color="accent"
            class="verification"
            [disabled]="isGettingVerificationUrl"
            [appButtonLoader]="isGettingVerificationUrl"
            (click)="getVerificationUrl()"
          >
            Verify your identity
          </button>
        </div>

        <!-- pass [isEDD] before [documents] so isEDD can filter out another files from documents in edd 
        check if viewOnly acts as we want to
        -->
        <app-verified-document-form
          *ngIf="verificationAccepted"
          [isEDD]="isEDD"
          [disableFileRemove]="true"
          [documents]="onboardingData.documents"
          (isComplete)="step1Control.setValue($event)"
        ></app-verified-document-form>
      </div>
    </app-expansion-panel-new>

    <!-- STEP 3 - Fill in the EDD form -->
    <app-expansion-panel-new
      *ngIf="isEDD"
      [expanded]="true"
      [name]="'Upload additional documents'"
      [isComplete]="undefined"
      data-testid="upload-additional-documents-form"
    >
      <!-- Extra documents to be requested for EDD -->
      <ng-container>
        <app-edd-document-form
          [documents]="onboardingData.documents"
          [eddDocumentTypes]="eddDocumentTypes"
        ></app-edd-document-form>
      </ng-container>
    </app-expansion-panel-new>
    <button
      *ngIf="appCompleteForm.valid && isEDD"
      mat-flat-button
      color="accent"
      fxFlexAlign="end"
      fxFlexAlign.lt-md="center"
      data-testid="submit-your-application-bottom-button"
      (click)="submit()"
    >
      Submit your application
    </button>

    <button
      *ngIf="appCompleteForm.valid && !isEDD"
      mat-flat-button
      color="accent"
      fxFlexAlign="end"
      fxFlexAlign.lt-md="center"
      data-testid="submit-your-application-bottom-button"
      (click)="submit()"
    >
      Submit your application
    </button>
  </ng-container>
</app-onboarding-layout-new>
