export enum OnboardingFieldId {
  BUSINESS = 'corporateForm',
  BUSINESS_NAME = 'corporateForm.businessName',
  BUSINESS_TRADING_NAME = 'corporateForm.businessTradingName',
  BUSINESS_TAX_NUMBER = 'corporateForm.taxNumber',
  BUSINESS_VAT_NUMBER = 'corporateForm.vatNumber',
  BUSINESS_URLS = 'corporateForm.companyUrls',
  BUSINESS_ACTIVITY = 'corporateForm.companyActivity',
  BUSINESS_ACTIVITY_DESCR = 'corporateForm.companyActivityDescr',
  BUSINESS_IS_REGULATED = 'corporateForm.isRegulated',
  BUSINESS_REGULATORY_AUTHORITY = 'corporateForm.regulatoryAuthority',
  BUSINESS_CAN_FUND_FROM_OWN_ACCOUNT = 'corporateForm.canFundFromOwnAccount',
  BUSINESS_ANNUAL_INCOME = 'corporateForm.annualIncome',
  BUSINESS_ACCOUNT_PURPOSE = 'corporateForm.accountPurpose',
  BUSINESS_MONTHLY_LOADING = 'corporateForm.monthlyLoading',
  BUSINESS_PERCENTAGE_OF_CASH = 'corporateForm.percentageOfCash',
  BUSINESS_DOUBLE_ANNUAL_INCOME = 'corporateForm.doubleAnnualIncome',
  BUSINESS_ZERO_BALANCE_STATEMENT = 'corporateForm.zeroBalanceStatement',
  BUSINESS_LEGAL_STATUS = 'corporateForm.legalStatus',
  BUSINESS_APPLY_ON_BEHALF_OF_COMPANY = 'corporateForm.applyOnBehalfOfCompany',
  BUSINESS_ABLE_DISCLOSE_INFORMATION = 'corporateForm.ableDiscloseInformation',
  BUSINESS_ACTIVITIES_NOT_INCLUDE = 'corporateForm.activitiesNotInclude',
  BUSINESS_DATA_IS_CORRECT_INFORMATION = 'corporateForm.isCorrectInformation',
  BUSINESS_OPERATE_IN_HIGH_RISK_COUNTRIES = 'corporateForm.operateInHighRiskCountries',
  BUSINESS_IS_FINANCIAL_STATEMENT_AUDITED = 'corporateForm.isFinancialStatementAudited',
  BUSINESS_EXPECTED_NUMBER_OF_PHYSICAL_CARDS = 'corporateForm.expectedNumOfPhysicalCards',
  BUSINESS_DATE_OF_INCORPORATION = 'corporateForm.incorporationDate',
  BUSINESS_BANK_PAYMENT_INSTITUTION_NAME = 'companyAccounts.name',
  BUSINESS_BANK_PAYMENT_INSTITUTION_COUNTRY = 'companyAccounts.country',
  BUSINESS_COUNTERPARTY_NAME = 'companyCounterparties.name',
  BUSINESS_COUNTERPARTY_COUNTRY = 'companyCounterparties.country',
  BUSINESS_COUNTERPARTY_BUSINESS_ACTIVITY = 'companyCounterparties.businessActivity',
  BUSINESS_COUNTERPARTY_INCOMING_TRANSACTION = 'companyCounterparties.isIncomingTransaction',
  BUSINESS_COUNTERPARTY_OUTGOING_TRANSACTION = 'companyCounterparties.isOutgoingTransaction',
  BUSINESS_KEY_PERSON_FIRST_NAME = 'companyDirectors.firstName',
  BUSINESS_KEY_PERSON_LAST_NAME = 'companyDirectors.lastName',
  BUSINESS_KEY_PERSON_EMAIL = 'companyDirectors.email',
  BUSINESS_KEY_PERSON_DATE_OF_BIRTH = 'companyDirectors.dateOfBirth',
  BUSINESS_KEY_PERSON_NATIONALITY = 'companyDirectors.nationality',
  BUSINESS_KEY_PERSON_ADDRESS = 'companyDirectors.address.streetAddress',
  BUSINESS_KEY_PERSON_ADDRESS_2ND_LINE = 'companyDirectors.address.additionalStreetAddress',
  BUSINESS_KEY_PERSON_ZIP_POSTAL_CODE = 'companyDirectors.address.postCode',
  BUSINESS_KEY_PERSON_CITY = 'companyDirectors.address.city',
  BUSINESS_KEY_PERSON_COUNTRY = 'companyDirectors.address.countryId',
  BUSINESS_KEY_PERSON_PHONE_NUMBER = 'companyDirectors.phoneNumber',
  BUSINESS_KEY_PERSON_AUTHORIZED_CONTACT_PERSON = 'companyDirectors.isAuthorizedPerson',
  BUSINESS_KEY_PERSON_DIRECTOR = 'companyDirectors.isDirector',
  BUSINESS_KEY_PERSON_SHAREHOLDER = 'companyDirectors.isShareholder',
  BUSINESS_KEY_PERSON_SHAREHOLDER_SHARES = 'companyDirectors.percentageOwnedByShareholder',
  BUSINESS_KEY_PERSON_UBO = 'companyDirectors.isUBO',
  BUSINESS_KEY_PERSON_UBO_SHARES = 'companyDirectors.percentageOwnedByUBO',
  BUSINESS_KEY_PERSON_TAX_NUMBER = 'companyDirectors.taxNumber',
  BUSINESS_KEY_PERSON_TAX_RESIDENCY_COUNTRY = 'companyDirectors.taxResidencyCountry',
  BUSINESS_KEY_PERSON_IS_PEP = 'companyDirectors.isPEP',
  BUSINESS_KEY_PERSON_LEGAL_PERSON_NAME = 'companyDirectors.legalPersonName',
  BUSINESS_KEY_PERSON_REGISTRATION_NUMBER = 'companyDirectors.legalPersonRegistrationNumber',
  BUSINESS_KEY_PERSON_DATE_OF_INCORPORATION = 'companyDirectors.legalPersonIncorporationDate',
  BUSINESS_COUNTRY_OF_INCORPORATION = 'corporateForm.incorporationAddress.countryId',
  BUSINESS_REGISTRATION_NUMBER = 'corporateForm.registrationNumber',
  BUSINESS_ADDRESS = 'corporateForm.incorporationAddress.streetAddress',
  BUSINESS_ADDRESS_2ND_LINE = 'corporateForm.incorporationAddress.additionalStreetAddress',
  BUSINESS_ZIP_POSTAL_CODE = 'corporateForm.incorporationAddress.postCode',
  BUSINESS_CITY = 'corporateForm.incorporationAddress.city',
  BUSINESS_ADDITIONAL_PHONE_NUMBER = 'corporateForm.additionalPhoneNumber',
  BUSINESS_OPERATING_ADDRESS = 'corporateForm.operatingAddress.streetAddress',
  BUSINESS_OPERATING_ADDRESS_2ND_LINE = 'corporateForm.operatingAddress.additionalStreetAddress',
  BUSINESS_OPERATING_ZIP_POSTAL_CODE = 'corporateForm.operatingAddress.postCode',
  BUSINESS_OPERATING_CITY = 'corporateForm.operatingAddress.city',
  BUSINESS_OPERATING_COUNTRY = 'corporateForm.operatingAddress.countryId',
  BUSINESS_CLOSE_LINK_NAME = 'companyCloseLinks.name',
  BUSINESS_CLOSE_LINK_REGISTRATION_NUMBER = 'companyCloseLinks.registrationNumber',
  BUSINESS_CLOSE_LINK_DATE_OF_INCORPORATION = 'companyCloseLinks.incorporationDate',
  BUSINESS_CLOSE_LINK_ADDRESS = 'companyCloseLinks.address.streetAddress',
  BUSINESS_CLOSE_LINK_ADDRESS_2ND_LINE = 'companyCloseLinks.address.additionalStreetAddress',
  BUSINESS_CLOSE_LINK_ZIP_POSTAL_CODE = 'companyCloseLinks.address.postCode',
  BUSINESS_CLOSE_LINK_CITY = 'companyCloseLinks.address.city',
  BUSINESS_CLOSE_LINK_COUNTRY = 'companyCloseLinks.address.countryId',
  BUSINESS_CLOSE_LINK_DIRECTOR = 'companyCloseLinks.isDirector',
  BUSINESS_CLOSE_LINK_SHAREHOLDER = 'companyCloseLinks.isShareholder',
  BUSINESS_CLOSE_LINK_SHAREHOLDER_SHARES = 'companyCloseLinks.percentageOwnedByShareholder',
  BUSINESS_CLOSE_LINK_UBO = 'companyCloseLinks.isUBO',
  BUSINESS_CLOSE_LINK_UBO_SHARES = 'companyCloseLinks.percentageOwnedByUBO',
  BUSINESS_GROUP_MEMBER_NAME = 'companyGroupMembers.name',
  BUSINESS_GROUP_MEMBER_REGISTRATION_NUMBER = 'companyGroupMembers.registrationNumber',
  BUSINESS_GROUP_MEMBER_DATE_OF_INCORPORATION = 'companyGroupMembers.incorporationDate',
  BUSINESS_GROUP_MEMBER_ADDRESS = 'companyGroupMembers.address.streetAddress',
  BUSINESS_GROUP_MEMBER_ADDRESS_2ND_LINE = 'companyGroupMembers.address.additionalStreetAddress',
  BUSINESS_GROUP_MEMBER_ZIP_POSTAL_CODE = 'companyGroupMembers.address.postCode',
  BUSINESS_GROUP_MEMBER_CITY = 'companyGroupMembers.address.city',
  BUSINESS_GROUP_MEMBER_COUNTRY = 'companyGroupMembers.address.countryId',
  PERSONAL = 'personalForm',
  PERSONAL_FIRST_NAME = 'personalForm.firstName',
  PERSONAL_LAST_NAME = 'personalForm.lastName',
  PERSONAL_DATE_OF_BIRTH = 'personalForm.dateOfBirth',
  PERSONAL_COUNTRY_OF_BIRTH = 'personalForm.countryOfBirth',
  PERSONAL_NATIONALITY = 'personalForm.nationality',
  PERSONAL_ADDRESS = 'personalForm.address.streetAddress',
  PERSONAL_ADDRESS_2ND_LINE = 'personalForm.address.additionalStreetAddress',
  PERSONAL_ZIP_POSTAL_CODE = 'personalForm.address.postCode',
  PERSONAL_CITY = 'personalForm.address.city',
  PERSONAL_COUNTRY = 'personalForm.address.countryId',
  PERSONAL_ADDITIONAL_PHONE_NUMBER = 'personalForm.additionalPhoneNumber',
  PERSONAL_ADDITIONAL_EMAIL = 'personalForm.additionalEmail',
  PERSONAL_EMAIL_OF_REFEREE = 'personalForm.referee',
  PERSONAL_HEARD_FROM = 'personalForm.heardFrom',
  PERSONAL_DATA_OCCUPATION_PROFESSION = 'personalForm.dataOccupationProfession',
  PERSONAL_DATA_OCCUPATION = 'personalForm.dataOccupation',
  PERSONAL_DATA_CARD_PURPOSE = 'personalForm.dataCardPurpose',
  PERSONAL_DATA_MONTHLY_INCOME = 'personalForm.dataMonthlyIncome',
  PERSONAL_DATA_MONTHLY_SPEND = 'personalForm.dataMonthlySpend',
  PERSONAL_DATA_IS_PEP = 'personalForm.isPEP',
  PERSONAL_DATA_SOURCE_OF_WEALTH = 'personalForm.dataSourceOfWealth',
  PERSONAL_DATA_SOURCE_OF_INCOME = 'personalForm.dataSourceOfIncome',
  PERSONAL_DATA_COUNTRY_OF_SOURCE_OF_WEALTH = 'personalForm.countryOfSourceOfWealth',
  PERSONAL_DATA_COUNTRY_OF_SOURCE_OF_INCOME = 'personalForm.countryOfSourceOfIncome',
  PERSONAL_DATA_SIZE_OF_WEALTH = 'personalForm.dataSizeOfWealth',
  PERSONAL_DATA_IS_CORRECT_INFORMATION = 'personalForm.isCorrectInformation',
  PERSONAL_DATA_TAX_NUMBER = 'personalForm.dataTaxNumber',
  PERSONAL_DATA_TAX_AND_VAT_COUNTRY = 'personalForm.dataTaxAndVatCountry',
  PERSONAL_DATA_ACTIVITIES_NOT_INCLUDE = 'personalForm.activitiesNotInclude',
  PERSONAL_DATA_VAT_NUMBER = 'personalForm.dataVatNumber',
  BUSINESS_TAX_AND_VAT_COUNTRY = 'corporateForm.taxAndVatCountry',
  PERSONAL_DATA_IS_TEMP_ADDRESS = 'personalForm.isTempAddress',
  BUSINESS_KEY_PERSON_IS_TEMP_ADDRESS = 'companyDirectors.isTempAddress',
}
