<app-big-loader *ngIf="isLoading"></app-big-loader>
<div *ngIf="!isLoading" fxLayout="column" [formGroup]="transferForm">
  <!-- FIRST STEP -->
  <div
    *ngIf="!isConfirmationStep"
    fxLayout="column"
    fxLayoutGap="30px"
    class="first-step"
  >
    <app-accounts-selector
      [formGroup]="accountsGroup"
      [accounts]="accounts"
    ></app-accounts-selector>
    <app-transfer-details-form
      [formGroup]="otherFieldsGroup"
      [isBetween]="true"
      (shouldTransfer)="goToConfirmationStep()"
    ></app-transfer-details-form>
  </div>

  <!-- CONFIRMATION STEP -->
  <app-transfer-confirmation
    *ngIf="isConfirmationStep"
    fxFlexAlign="center"
    [fromAccount]="fromAccountControl.value"
    [toAccount]="toAccountControl.value"
    [amount]="amountControl.value"
    [payerDescription]="payerDescriptionControl.value"
    [hasOtp]="false"
    [canSaveToFavourites]="false"
    [isTransferring]="isTransferring"
    [transactionId]="transactionId"
    [isBetween]="true"
    (cancel)="cancelTransfer($event)"
    (back)="back()"
    (transfer)="transfer()"
  ></app-transfer-confirmation>
</div>
