<div [formGroup]="declarationForm">
  <app-onboarding-form-item-new
    label="I am authorized and appointed by a Board of Directors Resolution to provide to eCREDO Limited  all necessary documentation for the opening of the account(s), to complete this application form and to carry out all communication with eCREDO Limited in relation to the opening of the account(s)."
    [isReviewed]="isReviewed"
    [value]="
      getStringValue(onboardingData?.corporateForm?.applyOnBehalfOfCompany)
    "
    [field]="
      fieldsMap.get(OnboardingFieldId.BUSINESS_APPLY_ON_BEHALF_OF_COMPANY)
    "
  >
    <div fxLayout="column">
      <mat-checkbox
        color="primary"
        name="applyOnBehalfOfCompany"
        formControlName="applyOnBehalfOfCompany"
        data-testid="apply-on-behalf-of-company-checkbox"
      >
        <h2 class="question">
          I am authorized and appointed by a Board of Directors Resolution to
          provide to eCREDO Limited all the necessary documentation for the
          opening of the account(s), to complete this application form and to
          carry out all communication with eCREDO Limited in relation to the
          opening of the account(s).
        </h2>
      </mat-checkbox>
      <br />
    </div>
  </app-onboarding-form-item-new>

  <app-onboarding-form-item-new
    label="I am able to disclose company's Ultimate Beneficial Owners (UBOs) and demonstrate adequate transparency of ownership information."
    [isReviewed]="isReviewed"
    [value]="
      getStringValue(onboardingData?.corporateForm?.ableDiscloseInformation)
    "
    [field]="
      fieldsMap.get(OnboardingFieldId.BUSINESS_ABLE_DISCLOSE_INFORMATION)
    "
  >
    <div fxLayout="column">
      <mat-checkbox
        color="primary"
        name="ableDiscloseInformation"
        formControlName="ableDiscloseInformation"
        data-testid="able-to-disclose-information-checkbox"
      >
        <h2 class="question">
          I am able to disclose company's Ultimate Beneficial Owners (UBOs) and
          demonstrate adequate transparency of ownership information.
        </h2>
      </mat-checkbox>
      <br />
    </div>
  </app-onboarding-form-item-new>

  <app-onboarding-form-item-new
    label="I acknowledge that business activities of the entity do NOT include: Arms & Weapons, Binary Options Trading/Cryptocurrency, Greencard via Lotteries, Illegal downloads, Illegal Gambling, Illegal Pharmaceuticals, Illegal Tobacco sales, Pyramid and Ponzi Schemes, Timeshares, Unregistered Charities, Unregulated Financial Services, Shell banks and companies."
    [isReviewed]="isReviewed"
    [value]="
      getStringValue(onboardingData?.corporateForm?.activitiesNotInclude)
    "
    [field]="fieldsMap.get(OnboardingFieldId.BUSINESS_ACTIVITIES_NOT_INCLUDE)"
  >
    <div fxLayout="column">
      <mat-checkbox
        color="primary"
        name="activitiesNotInclude"
        formControlName="activitiesNotInclude"
        data-testid="activities-checkbox"
      >
        <h2 class="question">
          I acknowledge that business activities of the entity do NOT include:
          Arms & Weapons, Binary Options Trading/Cryptocurrency, Greencard via
          Lotteries, Illegal downloads, Illegal Gambling, Illegal
          Pharmaceuticals, Illegal Tobacco sales, Pyramid and Ponzi Schemes,
          Timeshares, Unregistered Charities, Unregulated Financial Services,
          Shell banks and companies.
        </h2>
      </mat-checkbox>
      <br />
    </div>
  </app-onboarding-form-item-new>

  <app-onboarding-form-item-new
    label="I declare that the information that will be provided in this application form, is to the best of my knowledge, true, accurate and complete. I understand that I need to immediately inform eCREDO Limited about any changes."
    [isReviewed]="isReviewed"
    [value]="
      getStringValue(onboardingData?.corporateForm?.isCorrectInformation)
    "
    [field]="
      fieldsMap.get(OnboardingFieldId.BUSINESS_DATA_IS_CORRECT_INFORMATION)
    "
  >
    <div fxLayout="column">
      <mat-checkbox
        color="primary"
        name="isCorrectInformation"
        formControlName="isCorrectInformation"
        data-testid="is-correct-information-checkbox"
      >
        <h2 class="question">
          I declare that the information that will be provided in this
          application form, is to the best of my knowledge, true, accurate and
          complete. I understand that I need to immediately inform eCREDO
          Limited about any changes.
        </h2>
      </mat-checkbox>
      <br />
    </div>
  </app-onboarding-form-item-new>

  <div fxLayoutAlign="space-between center">
    <button
      *ngIf="!isReviewed"
      mat-flat-button
      color="accent"
      class="g-small"
      data-testid="save-declaration-form-button"
      [disabled]="declarationForm.invalid || isSaving"
      [appButtonLoader]="isSaving"
      (click)="saveDesclarationForm()"
    >
      Submit
    </button>
  </div>
</div>

<!-- TEMPLATE -->
<ng-template #review let-label="label" let-value="value" let-field="field">
  <ng-container *ngIf="isReviewed">
    <div
      fxLayout="row"
      fxLayoutGap="20px grid"
      class="submitted"
      [class.comment]="field?.comment"
    >
      <div fxFlex="50" class="name">{{ label }}</div>
      <div fxFlex="50" class="value">{{ field?.commentedValue || value }}</div>
    </div>
    <div *ngIf="field?.comment" class="comment">
      {{ field?.comment }}
    </div>
  </ng-container>
</ng-template>
