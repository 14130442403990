<h2 class="dark">Identity (required)</h2>

<ng-container [formGroup]="identityForm">
  <div fxLayout="column" class="field">
    <label for="firstName">First name</label>
    <mat-form-field appearance="outline">
      <input matInput name="firstName" formControlName="firstName" />
      <mat-error *ngIf="firstNameControl?.hasError('required')"
        >Required</mat-error
      >
    </mat-form-field>
  </div>
  <div fxLayout="column" class="field">
    <label for="lastName">Last name</label>
    <mat-form-field appearance="outline">
      <input matInput name="lastName" formControlName="lastName" />
      <mat-error *ngIf="lastNameControl?.hasError('required')"
        >Required</mat-error
      >
    </mat-form-field>
  </div>
  <div fxLayout="column" class="field">
    <label for="email">Email</label>
    <mat-form-field appearance="outline">
      <input matInput name="email" formControlName="email" />
    </mat-form-field>
  </div>
  <div fxLayout="column" class="field phone-number">
    <label for="cellPhoneNumber">Phone number</label>
    <ngx-intl-tel-input
      [enablePlaceholder]="false"
      [searchCountryFlag]="true"
      [separateDialCode]="true"
      name="cellPhoneNumber"
      formControlName="cellPhoneNumber"
    >
    </ngx-intl-tel-input>
    <div class="mat-form-field">
      <div class="mat-form-field-appearance-outline">
        <div class="mat-form-field-wrapper" style="margin-top: 0"></div>
      </div>
    </div>
  </div>

  <div fxLayout="column" class="field short">
    <label for="dateOfBirth">Date of Birth</label>
    <mat-form-field appearance="outline">
      <input
        matInput
        [matDatepicker]="picker"
        name="dateOfBirth"
        formControlName="dateOfBirth"
        [max]="minDob"
      />
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
      <mat-error *ngIf="dateOfBirthControl?.hasError('required')"
        >Required</mat-error
      >
    </mat-form-field>
  </div>
  <div fxLayout="column" class="field short">
    <label for="countryOfBirth">Country of Birth</label>
    <mat-form-field appearance="outline">
      <mat-select
        placeholder="Please select"
        name="countryOfBirth"
        formControlName="countryOfBirth"
      >
        <mat-option *ngFor="let country of countries" [value]="country.id">{{
          country.name | titlecase
        }}</mat-option>
      </mat-select>
      <mat-error *ngIf="countryOfBirthControl?.hasError('required')"
        >Required</mat-error
      >
    </mat-form-field>
  </div>
  <div fxLayout="column" class="field short">
    <label for="nationality">Nationality</label>
    <mat-form-field appearance="outline">
      <mat-select
        placeholder="Please select"
        name="nationality"
        formControlName="nationality"
      >
        <mat-option *ngFor="let country of countries" [value]="country.id">{{
          country.nationality | titlecase
        }}</mat-option>
      </mat-select>
      <mat-error *ngIf="nationalityControl?.hasError('required')"
        >Required</mat-error
      >
    </mat-form-field>
  </div>

  <h2 class="dark">Residential Address (required)</h2>
  <div fxLayout="column" class="field">
    <label>No PO Boxes</label>
    <label for="streetAddress">Address (Street, building number, place)</label>
    <mat-form-field appearance="outline">
      <input matInput name="streetAddress" formControlName="streetAddress" />
      <mat-error *ngIf="streetAddressControl?.hasError('required')"
        >Required</mat-error
      >
      <mat-error *ngIf="streetAddressControl?.hasError('pattern')"
        >Invalid character</mat-error
      >
      <mat-error *ngIf="streetAddressControl?.hasError('maxlength')"
        >Too many characters</mat-error
      >
    </mat-form-field>
  </div>
  <div fxLayout="column" class="field">
    <label for="additionalStreetAddress">Address 2nd line (optional)</label>
    <mat-form-field appearance="outline">
      <input
        matInput
        name="additionalStreetAddress"
        formControlName="additionalStreetAddress"
      />
      <mat-error *ngIf="additionalStreetAddressControl?.hasError('pattern')"
        >Invalid character</mat-error
      >
      <mat-error *ngIf="additionalStreetAddressControl?.hasError('maxlength')"
        >Too many characters</mat-error
      >
    </mat-form-field>
  </div>
  <div fxLayout="row" fxLayoutGap="35px">
    <div fxFlex="120px" fxLayout="column" class="zip">
      <label for="postCode">Zip/Postal code</label>
      <mat-form-field appearance="outline">
        <input matInput name="postCode" formControlName="postCode" />
        <mat-error *ngIf="postCodeControl?.hasError('required')"
          >Required</mat-error
        >
        <mat-error *ngIf="postCodeControl?.hasError('pattern')"
          >Invalid</mat-error
        >
        <mat-error *ngIf="postCodeControl?.hasError('maxlength')"
          >Too long</mat-error
        >
      </mat-form-field>
    </div>

    <div fxFlex="228px" fxLayout="column" class="short">
      <label for="city">City</label>
      <mat-form-field appearance="outline">
        <input matInput name="city" formControlName="city" />
        <mat-error *ngIf="cityControl?.hasError('required')"
          >Required</mat-error
        >
        <mat-error *ngIf="cityControl?.hasError('pattern')"
          >Invalid character</mat-error
        >
        <mat-error *ngIf="cityControl?.hasError('maxlength')"
          >Too many characters</mat-error
        >
      </mat-form-field>
    </div>
  </div>

  <div fxLayout="column" class="field short">
    <label for="countryId">Country</label>
    <mat-form-field appearance="outline">
      <mat-select
        placeholder="Please select"
        name="countryId"
        formControlName="countryId"
      >
        <mat-option
          *ngFor="let country of acceptedCountries"
          [value]="country.id"
          >{{ country.name | titlecase }}</mat-option
        >
      </mat-select>
      <mat-error *ngIf="countryIdControl?.hasError('required')"
        >Required</mat-error
      >
    </mat-form-field>
  </div>

  <div class="field">
    <mat-checkbox
      formControlName="isTempAddress"
      color="primary"
      class="checkbox"
      >The address above is my temporary residence in EEA</mat-checkbox
    >
  </div>

  <h2 class="dark">Additional phone number and email (optional)</h2>
  <div fxLayout="column" class="field phone-number">
    <label for="additionalPhoneNumber">Phone number</label>
    <ngx-intl-tel-input
      [enablePlaceholder]="false"
      [searchCountryFlag]="true"
      [separateDialCode]="true"
      name="additionalPhoneNumber"
      formControlName="additionalPhoneNumber"
    >
    </ngx-intl-tel-input>
    <div class="mat-form-field">
      <div class="mat-form-field-appearance-outline">
        <div class="mat-form-field-wrapper" style="margin-top: 0">
          <div class="mat-form-field-subscript-wrapper">
            <mat-error
              *ngIf="
                additionalPhoneNumberControl?.invalid &&
                additionalPhoneNumberControl?.touched
              "
              >Invalid</mat-error
            >
          </div>
        </div>
      </div>
    </div>
  </div>
  <div fxLayout="column" class="field">
    <label for="additionalEmail">Secondary email</label>
    <mat-form-field appearance="outline">
      <input
        matInput
        name="additionalEmail"
        formControlName="additionalEmail"
      />
      <mat-error *ngIf="additionalEmailControl?.hasError('email')"
        >Invalid</mat-error
      >
      <mat-error *ngIf="additionalEmailControl?.hasError('pattern')"
        >Invalid</mat-error
      >
    </mat-form-field>
  </div>

  <h2 class="dark">Occupation (required)</h2>

  <!-- QUIZ -->
  <div fxLayout="column" class="field">
    <label for="dataOccupationProfession"
      >What is your occupation status?</label
    >
    <mat-form-field appearance="outline">
      <mat-select
        placeholder="Please select"
        fxLayout="column"
        fxLayoutGap="14px"
        name="dataOccupationProfession"
        formControlName="dataOccupationProfession"
      >
        <mat-option
          *ngFor="let occupationProfession of occupationProfessions"
          [value]="occupationProfession[1]"
          >{{ occupationProfession[0] }}</mat-option
        >
      </mat-select>
      <mat-error
        data-testid="validation-message-box"
        *ngIf="dataOccupationProfessionControl?.hasError('required')"
        >Required</mat-error
      >
    </mat-form-field>
  </div>

  <div fxLayout="column" class="field">
    <label for="dataOccupation">What industry are you involved in?</label>
    <mat-form-field appearance="outline">
      <mat-select
        placeholder="Please select"
        fxLayout="column"
        fxLayoutGap="14px"
        name="dataOccupation"
        formControlName="dataOccupation"
      >
        <mat-option
          style="
            height: auto;
            white-space: normal;
            line-height: initial;
            padding: 10px;
          "
          *ngFor="let occupation of occupations"
          [value]="occupation[1]"
          >{{ occupation[0] }}</mat-option
        >
      </mat-select>
      <mat-error
        data-testid="validation-message-box"
        *ngIf="dataOccupationControl?.hasError('required')"
        >Required</mat-error
      >
    </mat-form-field>
  </div>

  <h2>Account Information (required)</h2>

  <div fxLayout="column" class="field">
    <label for="dataCardPurpose"
      >What is the main purpose of your account?</label
    >
    <mat-form-field appearance="outline">
      <mat-select
        placeholder="Please select"
        name="dataCardPurpose"
        formControlName="dataCardPurpose"
        multiple
      >
        <mat-option
          *ngFor="let cardPurpose of cardPurposes"
          [value]="cardPurpose[0]"
          >{{ cardPurpose[0] }}</mat-option
        >
      </mat-select>
      <mat-error
        data-testid="validation-message-box"
        *ngIf="dataCardPurposeControl?.hasError('required')"
        >Required</mat-error
      >
    </mat-form-field>
  </div>

  <div fxLayout="column" class="field">
    <label for="dataMonthlyIncome">What is your monthly income?</label>
    <mat-form-field appearance="outline">
      <mat-select
        placeholder="Please select"
        fxLayout="column"
        fxLayoutGap="14px"
        name="dataMonthlyIncome"
        formControlName="dataMonthlyIncome"
      >
        <mat-option
          *ngFor="let monthlyIncome of monthlyIncomes"
          [value]="monthlyIncome[1]"
          >{{ monthlyIncome[0] }}</mat-option
        >
      </mat-select>
      <mat-error
        data-testid="validation-message-box"
        *ngIf="dataMonthlyIncomeControl?.hasError('required')"
        >Required</mat-error
      >
    </mat-form-field>
  </div>

  <!-- MONTHLY SPEND -->
  <div fxLayout="column" class="field">
    <label for="dataMonthlySpend">What is your monthly spend?</label>
    <mat-form-field appearance="outline">
      <mat-select
        placeholder="Please select"
        fxLayout="column"
        fxLayoutGap="14px"
        name="dataMonthlySpend"
        formControlName="dataMonthlySpend"
      >
        <mat-option
          *ngFor="let monthlySpend of monthlySpends"
          [value]="monthlySpend[1]"
          >{{ monthlySpend[0] }}</mat-option
        >
      </mat-select>
      <mat-error
        data-testid="validation-message-box"
        *ngIf="dataMonthlySpendControl?.hasError('required')"
        >Required</mat-error
      >
    </mat-form-field>
  </div>

  <!-- PEP -->
  <h2 class="dark">Politically Exposed Person (PEP) Details (required)</h2>
  <div fxLayout="column" class="field">
    <label class="question">
      Are you (or have you been in the 12 previous months) a PEP or a close
      family member (spouse, children, parents) or a close associate (any
      business relations) of a PEP?
    </label>
    <mat-radio-group
      fxLayout="row"
      fxLayoutGap="14px"
      name="isPEP"
      formControlName="isPEP"
    >
      <mat-radio-button [value]="true">
        <div>Yes</div>
      </mat-radio-button>
      <mat-radio-button [value]="false">
        <div>No</div>
      </mat-radio-button>
    </mat-radio-group>
  </div>

  <h2>Tax/VAT Details</h2>
  <!-- TAX IDENTIFICATION NUMBER -->
  <div fxLayout="column" fxLayoutAlign="start start">
    <div fxLayout="column" class="field">
      <label for="dataTaxNumber">Tax Identification Number</label>
      <mat-form-field appearance="outline">
        <input
          data-testid="tax-number-input"
          matInput
          name="dataTaxNumber"
          formControlName="dataTaxNumber"
        />
        <mat-error
          data-testid="validation-message-box"
          *ngIf="dataTaxNumberControl?.hasError('required')"
          >Required</mat-error
        >
        <mat-error
          data-testid="validation-message-box"
          *ngIf="dataTaxNumberControl?.hasError('pattern')"
          >Invalid character</mat-error
        >
        <mat-error
          data-testid="validation-message-box"
          *ngIf="dataTaxNumberControl?.hasError('maxlength')"
          >Too many characters</mat-error
        >
      </mat-form-field>
    </div>
    <mat-checkbox
      formControlName="dataTaxNumberNotAvailable"
      color="primary"
      class="checkbox"
      >Tax Identification Number not available</mat-checkbox
    >
  </div>

  <!-- VAT NUMBER -->
  <div fxLayout="column" class="field">
    <label for="dataVatNumber">VAT Number</label>
    <mat-form-field appearance="outline">
      <input
        data-testid="vat-number-input"
        matInput
        name="dataVatNumber"
        formControlName="dataVatNumber"
      />
      <mat-error
        data-testid="validation-message-box"
        *ngIf="dataVatNumberControl?.hasError('required')"
        >Required</mat-error
      >
      <mat-error
        data-testid="validation-message-box"
        *ngIf="dataVatNumberControl?.hasError('pattern')"
        >Invalid character</mat-error
      >
      <mat-error
        data-testid="validation-message-box"
        *ngIf="dataVatNumberControl?.hasError('maxlength')"
        >Too many characters</mat-error
      >
    </mat-form-field>
  </div>
  <mat-checkbox
    formControlName="dataVatNumberNotAvailable"
    color="primary"
    class="checkbox"
    >VAT Number not available</mat-checkbox
  >

  <!-- COUNTRY OF TAX RESIDENCY/VAT NUMBER -->
  <div
    fxLayout="column"
    class="field short"
    *ngIf="
      dataTaxNumberControl?.value !== NA || dataVatNumberControl?.value !== NA
    "
  >
    <label for="dataTaxAndVatCountry"
      >Country of Tax Residency/VAT Number</label
    >
    <mat-form-field appearance="outline">
      <mat-select
        placeholder="Please select"
        name="dataTaxAndVatCountry"
        formControlName="dataTaxAndVatCountry"
      >
        <mat-option
          *ngFor="let dataTaxAndVatCountry of countries"
          [value]="dataTaxAndVatCountry.id"
          >{{ dataTaxAndVatCountry.name | titlecase }}</mat-option
        >
      </mat-select>
      <mat-error
        data-testid="validation-message-box"
        *ngIf="dataTaxAndVatCountryControl?.hasError('required')"
        >Required</mat-error
      >
    </mat-form-field>
  </div>

  <ng-container *ngIf="showExtraQuestions">
    <h2 class="questions">Additional financial information (required)</h2>
    <!-- SOURCE OF WEALTH -->
    <div fxLayout="column" class="field">
      <h2>Source of Wealth</h2>
      <mat-form-field appearance="outline">
        <mat-select
          placeholder="Please select"
          name="dataSourceOfWealth"
          formControlName="dataSourceOfWealth"
          multiple
        >
          <mat-option
            *ngFor="let sourceOfWealth of sourcesOfWealth"
            [value]="sourceOfWealth[0]"
            >{{ sourceOfWealth[0] }}</mat-option
          >
        </mat-select>
        <mat-error
          data-testid="validation-message-box"
          *ngIf="dataSourceOfWealthControl?.hasError('required')"
          >Required</mat-error
        >
      </mat-form-field>
    </div>

    <!-- COUNTRY OF SOURCE OF WEALTH -->
    <div fxLayout="column" class="field short">
      <label for="countryOfSourceOfWealth"
        >Country of Origin (Source of Wealth)</label
      >
      <mat-form-field appearance="outline">
        <mat-select
          placeholder="Please select"
          data-testid="country-of-wealth-select"
          name="countryOfSourceOfWealth"
          formControlName="countryOfSourceOfWealth"
        >
          <mat-option
            *ngFor="let country of countries"
            [value]="country.id"
            [attr.data-testid]="
              'country-of-wealth-' + country.code.toLowerCase()
            "
            >{{ country.name | titlecase }}</mat-option
          >
        </mat-select>
        <mat-error *ngIf="countryOfSourceOfWealthControl?.hasError('required')"
          >Required</mat-error
        >
      </mat-form-field>
    </div>

    <!-- SOURCE OF INCOME -->
    <div fxLayout="column" class="field">
      <h2>Source of Income</h2>
      <mat-form-field appearance="outline">
        <mat-select
          placeholder="Please select"
          name="dataSourceOfIncome"
          formControlName="dataSourceOfIncome"
          multiple
        >
          <mat-option
            *ngFor="let sourceOfIncome of sourcesOfIncome"
            [value]="sourceOfIncome[0]"
            >{{ sourceOfIncome[0] }}</mat-option
          >
        </mat-select>
        <mat-error
          data-testid="validation-message-box"
          *ngIf="dataSourceOfIncomeControl?.hasError('required')"
          >Required</mat-error
        >
      </mat-form-field>
    </div>

    <!-- COUNTRY OF SOURCE OF INCOME -->
    <div fxLayout="column" class="field short">
      <label for="countryOfSourceOfIncome"
        >Country of Origin (Source of Income)</label
      >
      <mat-form-field appearance="outline">
        <mat-select
          placeholder="Please select"
          data-testid="country-of-income-select"
          name="countryOfSourceOfIncome"
          formControlName="countryOfSourceOfIncome"
        >
          <mat-option
            *ngFor="let country of countries"
            [value]="country.id"
            [attr.data-testid]="
              'country-of-income-' + country.code.toLowerCase()
            "
            >{{ country.name | titlecase }}</mat-option
          >
        </mat-select>
        <mat-error *ngIf="countryOfSourceOfIncomeControl?.hasError('required')"
          >Required</mat-error
        >
      </mat-form-field>
    </div>

    <!-- SIZE OF WEALTH -->
    <div fxLayout="column" class="field">
      <h2>Size of Wealth</h2>
      <mat-form-field appearance="outline">
        <mat-select
          placeholder="Please select"
          name="dataSizeOfWealth"
          formControlName="dataSizeOfWealth"
        >
          <mat-option
            *ngFor="let sizeOfWealth of sizesOfWealth"
            [value]="sizeOfWealth[0]"
            >{{ sizeOfWealth[0] }}</mat-option
          >
        </mat-select>
        <mat-error
          data-testid="validation-message-box"
          *ngIf="dataSizeOfWealthControl?.hasError('required')"
          >Required</mat-error
        >
      </mat-form-field>
    </div>
  </ng-container>

  <!-- EMAIL OF YOUR REFEREE (OPTIONAL) -->
  <div fxLayout="column" class="field">
    <label for="referee">Email of your referee (optional)</label>
    <mat-form-field appearance="outline">
      <input matInput name="referee" formControlName="referee" />
      <mat-error *ngIf="refereeControl?.hasError('email')">Invalid</mat-error>
    </mat-form-field>
  </div>
  <!-- HEARD FROM -->
  <div fxLayout="column" class="field">
    <label for="heardFrom">How did you hear about us? (optional)</label>
    <mat-form-field appearance="outline">
      <input matInput name="heardFrom" formControlName="heardFrom" />
      <mat-error
        data-testid="validation-message-box"
        *ngIf="heardFromControl?.hasError('pattern')"
        >Invalid character</mat-error
      >
      <mat-error *ngIf="heardFromControl?.hasError('maxlength')"
        >Too many characters</mat-error
      >
    </mat-form-field>
  </div>

  <div fxLayoutAlign="space-between center" class="save-button">
    <button
      mat-flat-button
      color="accent"
      [disabled]="
        identityForm.pristine || identityForm.invalid || isSavingIdentityForm
      "
      [appButtonLoader]="isSavingIdentityForm"
      (click)="saveIdentityUserForm()"
    >
      Save changes
    </button>
  </div>
</ng-container>
