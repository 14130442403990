<div
  class="main"
  fxLayoutGap="7px"
  [fxLayoutAlign]="isSmallVersion ? 'start center' : 'start stretch'"
  [class.small-version]="isSmallVersion"
>
  <!-- EMPTY STATE -->
  <div *ngIf="!account" class="choose-account" fxFlexAlign="center">
    Choose an account
  </div>

  <!-- IBAN -->
  <ng-container *ngIf="isIban">
    <mat-icon svgIcon="iban" color="accent"></mat-icon>
    <div
      fxLayout="column"
      fxLayoutAlign="space-between"
      class="right-container"
    >
      <div fxLayout fxLayoutAlign="space-between">
        <div class="account-name">IBAN Account</div>
        <div
          *ngIf="!isBalanceHidden"
          class="balance"
          data-testid="balance-label"
        >
          {{ balance | amount }} {{ currency }}
        </div>
      </div>
      <div
        *ngIf="!isSmallVersion"
        fxLayout
        class="iban-number"
        fxLayoutGap="6px"
      >
        <img
          src="assets/img/copy.svg"
          alt="copy"
          width="14"
          height="14"
          [cdkCopyToClipboard]="account?.iban || ''"
        />
        <span class="account-number">{{ account?.iban }}</span>
      </div>
    </div>
  </ng-container>

  <!-- CARD -->
  <ng-container *ngIf="isCard">
    <div fxLayout="column">
      <mat-icon svgIcon="card" color="accent"></mat-icon>
      <img
        *ngIf="!isSmallVersion"
        src="assets/img/master-card.svg"
        alt="master-card"
      />
    </div>

    <div
      fxLayout="column"
      fxLayoutAlign="space-between"
      class="right-container"
    >
      <div fxLayout fxLayoutAlign="space-between center">
        <div fxLayout="column" class="hidden-overflow">
          <!-- TODO code to card code -->
          <div class="account-name">Card - {{ account!.code.slice(-4) }}</div>
          <!-- TODO card owner 
            <div *ngIf="isSmallVersion" class="cardholder">
            {{ account!.owner.firstname | titlecase }}
            {{ account!.owner.lastname | titlecase }}
          </div> -->
        </div>
        <div *ngIf="!isBalanceHidden" class="balance">
          {{ balance | amount }} {{ currency }}
        </div>
      </div>
      <!-- TODO card owner
        <div *ngIf="!isSmallVersion" class="cardholder">
        {{ account!.owner.firstname | titlecase }}
        {{ account!.owner.lastname | titlecase }}
      </div> -->
    </div>
  </ng-container>

  <!-- WALLET -->
  <ng-container *ngIf="isWallet">
    <div fxLayout fxLayoutAlign="space-between center" class="right-container">
      <div class="account-name">
        <div fxLayoutAlign="center">
          <img [src]="imagePath(currency)" [alt]="currency" />
          <span>{{ currency }} Wallet</span>
        </div>
      </div>
      <div *ngIf="!isBalanceHidden" class="balance">
        {{ balance | amount }} {{ currency }}
      </div>
    </div>
  </ng-container>
</div>
