import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';

import locale from '@angular/common/locales/en-FI';
import { DatePipe, DecimalPipe, registerLocaleData } from '@angular/common';

import { JwtModule } from '@auth0/angular-jwt';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/components/login/login.component';
import { MainDashboardComponent } from './main-dashboard/main-dashboard.component';
import { environment } from 'src/environments/environment';
import { IdleDialogComponent } from './shared/idle-dialog/idle-dialog.component';
import { MaterialModule } from './material.module';
import { RegistrationComponent } from './login/components/registration/registration.component';
import { ResetPasswordComponent } from './login/components/reset-password/reset-password.component';
import { ChooseAccountComponent } from './login/components/registration/choose-account/choose-account.component';
import { AccountDetailsDialogComponent } from './login/components/registration/choose-account/account-details-dialog/account-details-dialog.component';
import { AccountHeaderComponent } from './login/components/registration/choose-account/account-header/account-header.component';
import { ProvideDataComponent } from './login/components/registration/provide-data/provide-data.component';
import { EmailSentComponent } from './login/components/registration/email-sent/email-sent.component';
import { ConnectPhoneComponent } from './login/components/further-registration/connect-phone/connect-phone.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { BaseLoginPageComponent } from './login/shared/base-login-page/base-login-page.component';
import { LoginCardComponent } from './login/shared/login-card/login-card.component';
import { AuthInterceptor } from './login/auth.interceptor';
import { PasswordComponent } from './login/shared/password/password.component';
import { ProvideEmailComponent } from './login/components/reset-password/provide-email/provide-email.component';
import { ProvideNewPasswordComponent } from './login/components/reset-password/provide-new-password/provide-new-password.component';
import { OnboardingNewComponent } from './onboarding-new/onboarding.component';
import { HeaderComponent } from './shared/header/header.component';
import { NgxFileDropModule } from 'ngx-file-drop';
import { FileInputComponent } from './shared/file-input/file-input.component';
import { StepperNewComponent } from './onboarding-new/stepper/stepper.component';
import { OnboardingSubmitDialogNewComponent } from './onboarding-new/components/onboarding-submit-dialog/onboarding-submit-dialog.component';
import { OnboardingIndividualFormNewComponent } from './onboarding-new/components/onboarding-individual-form/onboarding-individual-form.component';
import { OnboardingIndividualSubmittedNewComponent } from './onboarding-new/components/onboarding-individual-submitted/onboarding-individual-submitted.component';
import { OnboardingBusinessFormNewComponent } from './onboarding-new/components/onboarding-business-form/onboarding-business-form.component';
import { ExpansionPanelNewComponent } from './onboarding-new/shared/expansion-panel/expansion-panel.component';
import { DirectorShareholderFormNewComponent } from './onboarding-new/shared/director-shareholder-form/director-shareholder-form.component';
import { OrdinalNumberPipe } from './shared/ordinal-number.pipe';
import { MatLuxonDateModule } from '@angular/material-luxon-adapter';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { BusinessDocumentsFormNewComponent } from './onboarding-new/shared/business-documents-form/business-documents-form.component';
import { FillOnboardingBusinessFormNewComponent } from './onboarding-new/components/fill-onboarding-business-form/fill-onboarding-business-form.component';
import { CompanyDirectorsFormNewComponent } from './onboarding-new/shared/company-directors-form/company-directors-form.component';
import { OnboardingBusinessSubmittedNewComponent } from './onboarding-new/components/onboarding-business-submitted/onboarding-business-submitted.component';
import { ButtonLoaderDirective } from './shared/button-loader/button-loader.directive';
import { BigLoaderComponent } from './shared/big-loader/big-loader.component';
import { ErrorDialogComponent } from './shared/error-dialog/error-dialog.component';
import { InfoDialogComponent } from './shared/info-dialog/info-dialog.component';
import { ConfirmEmailComponent } from './login/components/further-registration/confirm-email/confirm-email.component';
import { AdminDashboardComponent } from './admin/admin-dashboard/admin-dashboard.component';
import { UsersComponent } from './admin/users/users.component';
import { RegisteredUsersComponent } from './admin/users/registered-users/registered-users.component';
import { AdminDocumentComponent } from './admin/users/ongoing-registration-new/ongoing-registration-user/admin-document/admin-document.component';
import { AdminFieldComponent } from './admin/users/ongoing-registration-new/ongoing-registration-user/admin-field/admin-field.component';
import { AdminNoteDialogComponent } from './admin/users/ongoing-registration-new/admin-note-dialog/admin-note-dialog.component';
import { AdminFileDialogComponent } from './admin/users/ongoing-registration-new/admin-file-dialog/admin-file-dialog.component';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { AdminConversationDialogNewComponent } from './onboarding-new/shared/admin-conversation-dialog/admin-conversation-dialog.component';
import { UserProfileNewComponent } from './user-profile-new/user-profile.component';
import { UserProfileIndividualNewComponent } from './user-profile-new/components/user-profile-individual/user-profile-individual.component';
import { UserProfileBusinessNewComponent } from './user-profile-new/components/user-profile-business/user-profile-business.component';
import { ChangeEmailDialogNewComponent } from './user-profile-new/components/change-email-dialog/change-email-dialog.component';
import { ChangeEmailSuccessDialogNewComponent } from './user-profile-new/components/change-email-success-dialog/change-email-success-dialog.component';
import { ConfirmEmailChangeNewComponent } from './user-profile-new/components/confirm-email-change/confirm-email-change.component';
import { ChangePhoneNumberDialogNewComponent } from './user-profile-new/components/change-phone-number-dialog/change-phone-number-dialog.component';
import { ChangePasswordDialogNewComponent } from './user-profile-new/components/change-password-dialog/change-password-dialog.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { TransferComponent } from './transfer/transfer.component';
import { StatementComponent } from './statement/statement.component';
import { AccountSectionComponent } from './dashboard/account-section/account-section.component';
import { AccountSectionHeaderComponent } from './dashboard/account-section/account-section-header/account-section-header.component';
import { OnboardingFormItemNewComponent } from './onboarding-new/shared/onboarding-form-item/onboarding-form-item.component';
import { ReviewedInfoNewComponent } from './onboarding-new/shared/reviewed-info/reviewed-info.component';
import { VerifyClientDialogComponent } from './admin/users/ongoing-registration-new/ongoing-registration-user/verify-client-dialog/verify-client-dialog.component';
import { BillingSectionComponent } from './dashboard/account-section/account-sub-section/billing-section/billing-section.component';
import { CardSectionEmptyStateComponent } from './dashboard/account-section/account-sub-section/card-section-empty-state/card-section-empty-state.component';
import { AccountSubSectionComponent } from './dashboard/account-section/account-sub-section/account-sub-section.component';
import { CardsDropdownComponent } from './dashboard/account-section/account-section-header/cards-dropdown/cards-dropdown.component';
import { InBetweenComponent } from './transfer/in-between/in-between.component';
import { E2eNowComponent } from './transfer/e2e-now/e2e-now.component';
import { ExternalComponent } from './transfer/external/external.component';
import { MassPaymentsComponent } from './transfer/mass-payments/mass-payments.component';
import { RecurringComponent } from './transfer/recurring/recurring.component';
import { FavouritesComponent } from './transfer/favourites/favourites.component';
import { AvatarInputNewComponent } from './user-profile-new/shared/avatar-input/avatar-input.component';
import { AccountInformationDialogNewComponent } from './user-profile-new/components/account-information-dialog/account-information-dialog.component';
import { TransferDetailsFormComponent } from './transfer/shared/transfer-details-form/transfer-details-form.component';
import { CardActivationDialogComponent } from './dashboard/account-section/card-activiation-dialog/card-activation-dialog.component';
import { SmallLoaderComponent } from './shared/small-loader/small-loader.component';
import { TransferConfirmationComponent } from './transfer/shared/transfer-confirmation/transfer-confirmation.component';
import { RegisteredUserComponent } from './admin/users/registered-users/registered-user/registered-user.component';
import { AccountListItemComponent } from './shared/account-list-item/account-list-item.component';
import { AccountsSelectorComponent } from './transfer/in-between/accounts-selector/accounts-selector.component';
import { RegisteredUserDocumentsComponent } from './admin/users/registered-users/registered-user/registered-user-documents/registered-user-documents.component';
import { AllDocumentsFormComponent } from './admin/users/registered-users/registered-user/registered-user-documents/components/all-documents-form/all-documents-form.component';
import { AllFileInputComponent } from './admin/users/registered-users/registered-user/registered-user-documents/components/all-file-input/all-file-input.component';
import { RegisteredUserLogsComponent } from './admin/users/registered-users/registered-user/registered-user-logs/registered-user-logs.component';
import { LogDialogComponent } from './admin/users/registered-users/registered-user/registered-user-logs/log-dialog/log-dialog.component';
import { AccountSectionCardHeaderComponent } from './dashboard/account-section/account-section-card-header/account-section-card-header.component';
import { SubDashboardComponent } from './sub-dashboard/sub-dashboard.component';
import { CardsComponent } from './sub-dashboard/cards/cards.component';
import { IbanAccountComponent } from './sub-dashboard/iban-account/iban-account.component';
import { WalletComponent } from './sub-dashboard/wallet/wallet.component';
import { E2eAccountsSelectorComponent } from './transfer/e2e-now/e2e-accounts-selector/e2e-accounts-selector.component';
import { DocumentsTableComponent } from './admin/users/registered-users/registered-user/registered-user-documents/components/documents-table/documents-table.component';
import { BigLoaderNotCenteredComponent } from './shared/big-loader-not-centered/big-loader-not-centered.component';
import { PendingChangesGuard } from './guards/pending-changes-guard';
import { ReportsComponent } from './admin/reports/reports.component';
import { ActionsComponent } from './admin/actions/actions.component';
import { ActivationComponent } from './admin/actions/activation/activation.component';
import { ActivationFileUploadComponent } from './admin/actions/activation/activation-file-upload/activation-file-upload.component';
import { ExpiringDocumentsComponent } from './admin/reports/expiring-documents/expiring-documents.component';
import { UserIpLoginComponent } from './admin/reports/user-ip-login/user-ip-login.component';
import { InitialPaymentComponent } from './login/components/initial-payment/initial-payment.component';
import { InitialPaymentFailComponent } from './login/components/initial-payment-fail/initial-payment-fail.component';
import { InitialPaymentSuccessComponent } from './login/components/initial-payment-success/initial-payment-success.component';
import { CardPaymentDialogComponent } from './shared/card-payment-dialog/card-payment-dialog.component';
import { OrderNewCardDialogComponent } from './dashboard/account-section/order-new-card-dialog/order-new-card-dialog.component';
import { StatementPdfComponent } from './statement/statement-pdf/statement-pdf.component';
import { ExtAccountsSelectorComponent } from './transfer/external/ext-accounts-selector/ext-accounts-selector.component';
import { SubDashboardHeaderComponent } from './sub-dashboard/components/sub-dashboard-header/sub-dashboard-header.component';
import { TemplateUploadComponent } from './transfer/mass-payments/template-upload/template-upload.component';
import { MassPaymentTableComponent } from './transfer/mass-payments/mass-payment-table/mass-payment-table.component';
import { ActivationFileTableComponent } from './admin/actions/activation/activation-file-table/activation-file-table.component';
import { MassPaymentsSnackbarComponent } from './transfer/mass-payments/mass-payments-snackbar/mass-payments-snackbar.component';
import { ActivationSnackbarComponent } from './admin/actions/activation/activation-snackbar/activation-snackbar.component';
import { CurrencyExchangeComponent } from './sub-dashboard/wallet/currency-exchange/currency-exchange.component';
import { BuyCurrencyDialogComponent } from './sub-dashboard/wallet/currency-exchange/buy-currency-dialog/buy-currency-dialog.component';
import { TopUpDialogComponent } from './sub-dashboard/iban-account/top-up-dialog/top-up-dialog.component';
import { TopUpResultDialogComponent } from './sub-dashboard/iban-account/top-up-dialog/top-up-result-dialog/top-up-result-dialog.component';
import { TransferSaveFavouriteComponent } from './transfer/shared/transfer-confirmation/transfer-save-favourite/transfer-save-favourite.component';
import { StatementDetailsPdfComponent } from './statement/statement-details-pdf/statement-details-pdf.component';
import { SelectFavouriteOverlayComponent } from './transfer/shared/select-favourite-overlay/select-favourite-overlay.component';
import { CardsTabButtonComponent } from './sub-dashboard/cards/cards-tab-button/cards-tab-button.component';
import { CardItemComponent } from './dashboard/account-section/account-section-header/cards-dropdown/card-item/card-item.component';
import { LockUnlockCardDialogComponent } from './sub-dashboard/cards/lock-unlock-card-dialog/lock-unlock-card-dialog.component';
import { ShowPinDialogComponent } from './sub-dashboard/cards/show-pin-dialog/show-pin-dialog.component';
import { ReportCardComponent } from './sub-dashboard/cards/report-card/report-card.component';
import { DeactivatedCardsDialogComponent } from './sub-dashboard/cards/deactivated-cards-dialog/deactivated-cards-dialog.component';
import { RegisteredUserAccountsComponent } from './admin/users/registered-users/registered-user/registered-user-accounts/registered-user-accounts.component';
import { AccountsTableComponent } from './admin/users/registered-users/registered-user/registered-user-accounts/accounts-table/accounts-table.component';
import { GenericAccountsTableComponent } from './admin/users/registered-users/registered-user/registered-user-accounts/generic-accounts-table/generic-accounts-table.component';
import { IbanCertificateComponent } from './sub-dashboard/iban-certificate/iban-certificate.component';
import { OnboardingLayoutNewComponent } from './onboarding-new/shared/onboarding-layout/onboarding-layout.component';
import { FooterComponent } from './shared';
import { OngoingRegistrationNewComponent } from './admin/users/ongoing-registration-new/ongoing-registration.component';
import { OngoingRegistrationUserNewComponent } from './admin/users/ongoing-registration-new/ongoing-registration-user/ongoing-registration-user.component';
import { AdminFormReviewNewComponent } from './admin/users/ongoing-registration-new/ongoing-registration-user/admin-form-review/admin-form-review.component';
import { UpdateChidsComponent } from './admin/actions/update-chids/update-chids.component';
import { OngoingRegistrationPdfNewComponent } from './admin/users/ongoing-registration-new/ongoing-registration-pdf/ongoing-registration-pdf.component';
import { EddDocumentFormComponent } from './onboarding-new/shared/edd-document-form/edd-document-form.component';
import { ClientDecisionDialogComponent } from './admin/users/ongoing-registration-new/ongoing-registration-user/client-decision-dialog/client-decision-dialog.component';
import { RegisteredUserProfileIndividualNewComponent } from './admin/users/registered-users/registered-user/registered-user-profile-new/registered-user-profile-individual-new/registered-user-profile-individual.component';
import { RegisteredUserProfileNewComponent } from './admin/users/registered-users/registered-user/registered-user-profile-new/registered-user-profile.component';
import { NotificationsComponent } from './admin/notifications/notifications.component';
import { RiskAssessmentComponent } from './admin/users/ongoing-registration-new/ongoing-registration-user/risk-assessment/risk-assessment.component';
import { CompanyCounterpartiesFormComponent } from './onboarding-new/shared/company-counterparties-form/company-counterparties-form.component';
import { CounterpartyFormComponent } from './onboarding-new/shared/counterparty-form/counterparty-form.component';
import { OnboardingBusinessDeclarationFormComponent } from './onboarding-new/components/onboarding-business-declaration-form/onboarding-business-declaration-form.component';
import { AccountFormComponent } from './onboarding-new/shared/account-form/account-form.component';
import { CompanyAccountsFormComponent } from './onboarding-new/shared/company-accounts-form/company-accounts-form.component';
import { CompanyCloseLinkDialogComponent } from './onboarding-new/shared/company-close-link-dialog/company-close-link-dialog.component';
import { CompanyCloseLinkSelectDialogComponent } from './onboarding-new/shared/company-close-link-select-dialog/company-close-link-select-dialog.component';
import { AdminDirectorShareholderNewComponent } from './admin/users/ongoing-registration-new/ongoing-registration-user/admin-director-shareholder-new/admin-director-shareholder.component';
import { AdminFieldNewComponent } from './admin/users/ongoing-registration-new/ongoing-registration-user/admin-field-new/admin-field.component';
import { DetailedReportDialogComponent } from './admin/users/ongoing-registration-new/ongoing-registration-user/risk-assessment/detailed-report-dialog/detailed-report-dialog.component';
import { CompanyGroupMembersFormComponent } from './onboarding-new/shared/company-group-members-form/company-group-members-form.component';
import { GroupMemberFormComponent } from './onboarding-new/shared/group-member-form/group-member-form.component';
import { OnboardingIndividualDeclarationFormComponent } from './onboarding-new/components/onboarding-individual-declaration-form/onboarding-individual-declaration-form.component';
import { RegisteredUserProfileCorporateNewComponent } from './admin/users/registered-users/registered-user/registered-user-profile-new/registered-user-profile-corporate-new/registered-user-profile-corporate-new.component';
import { CommifyPipe } from './shared/commify.pipe';
import { ActionLogsComponent } from './admin/users/ongoing-registration-new/ongoing-registration-user/action-logs/action-logs.component';
import { NoteDocumentFormComponent } from './onboarding-new/shared/note-document-form/note-document-form.component';
import { AdminNoteFileDialogComponent } from './admin/users/ongoing-registration-new/admin-note-file-dialog/admin-note-file-dialog.component';
import { VerifiedDocumentFormComponent } from './onboarding-new/shared/verified-document-form/verified-document-form.component';
import { VerificationDialogComponent } from './onboarding-new/components/verification-dialog/verification-dialog.component';
import { ShowAmlHitsDialogComponent } from './admin/users/ongoing-registration-new/ongoing-registration-user/risk-assessment/show-aml-hits-dialog/show-aml-hits-dialog.component';
import { ReplaceUnderscorePipe } from './shared/replace-underscore.pipe';
import { ShowAmlHitDialogComponent } from './admin/users/ongoing-registration-new/ongoing-registration-user/risk-assessment/show-aml-hit-dialog/show-aml-hit-dialog.component';
import { KeyPersonVerificationComponent } from './admin/users/ongoing-registration-new/ongoing-registration-user/risk-assessment/verification/key-person-verification.component';
import { KeyPersonVerifiedDocumentFormComponent } from './onboarding-new/shared/key-person-verified-document-form/key-person-verified-document-form.component';
import { DirectorDocumentFormComponent } from './onboarding-new/shared/director-document-form/director-document-form.component';
import { ShowKybHitsDialogComponent } from './admin/users/ongoing-registration-new/ongoing-registration-user/risk-assessment/show-kyb-hits-dialog/show-kyb-hits-dialog.component';
import { ShowKybHitDialogComponent } from './admin/users/ongoing-registration-new/ongoing-registration-user/risk-assessment/show-kyb-hit-dialog/show-kyb-hit-dialog.component';
import { SelectBeneficiaryOverlayComponent } from './transfer/shared/select-beneficiary-overlay/select-beneficiary-overlay.component';
import { AmountPipe } from './shared/amount.pipe';
import { PaymentsComponent } from './payments/payments.component';
import { ComingSoonSectionComponent } from './dashboard/account-section/account-sub-section/coming-soon-section/coming-soon-section.component';
import { ComingSoonHeaderComponent } from './dashboard/account-section/coming-soon-header/coming-soon-header.component';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { JsonViewerComponent } from './admin/users/ongoing-registration-new/ongoing-registration-user/risk-assessment/json-viewer/json-viewer.component';
import { StatementAmountPipe } from './shared/statement-amount.pipe';
import { StartWeekOnMondayDateAdapter } from './transfer/shared/start-week-on-monday-date-adapter';
import { DateAdapter } from '@angular/material/core';

registerLocaleData(locale);

@NgModule({
  declarations: [
    AppComponent,
    MainDashboardComponent,
    LoginComponent,
    IdleDialogComponent,
    RegistrationComponent,
    ResetPasswordComponent,
    ChooseAccountComponent,
    AccountDetailsDialogComponent,
    AccountHeaderComponent,
    ProvideDataComponent,
    EmailSentComponent,
    ConnectPhoneComponent,
    BaseLoginPageComponent,
    LoginCardComponent,
    PasswordComponent,
    ProvideEmailComponent,
    ProvideNewPasswordComponent,
    OnboardingNewComponent,
    HeaderComponent,
    FileInputComponent,
    StepperNewComponent,
    OnboardingSubmitDialogNewComponent,
    OnboardingIndividualFormNewComponent,
    OnboardingIndividualSubmittedNewComponent,
    OnboardingBusinessFormNewComponent,
    ExpansionPanelNewComponent,
    DirectorShareholderFormNewComponent,
    VerifiedDocumentFormComponent,
    DirectorDocumentFormComponent,
    KeyPersonVerifiedDocumentFormComponent,
    EddDocumentFormComponent,
    NoteDocumentFormComponent,
    OrdinalNumberPipe,
    CommifyPipe,
    ReplaceUnderscorePipe,
    AmountPipe,
    StatementAmountPipe,
    BusinessDocumentsFormNewComponent,
    FillOnboardingBusinessFormNewComponent,
    OnboardingBusinessDeclarationFormComponent,
    OnboardingIndividualDeclarationFormComponent,
    CompanyDirectorsFormNewComponent,
    CompanyCounterpartiesFormComponent,
    CounterpartyFormComponent,
    CompanyAccountsFormComponent,
    AccountFormComponent,
    CompanyGroupMembersFormComponent,
    GroupMemberFormComponent,
    OnboardingBusinessSubmittedNewComponent,
    ButtonLoaderDirective,
    BigLoaderComponent,
    ErrorDialogComponent,
    InfoDialogComponent,
    ConfirmEmailComponent,
    KeyPersonVerificationComponent,
    AdminDashboardComponent,
    UsersComponent,
    OngoingRegistrationNewComponent,
    RegisteredUsersComponent,
    OngoingRegistrationUserNewComponent,
    AdminDocumentComponent,
    AdminDirectorShareholderNewComponent,
    AdminFieldComponent,
    AdminFieldNewComponent,
    AdminFormReviewNewComponent,
    RiskAssessmentComponent,
    ActionLogsComponent,
    AdminNoteDialogComponent,
    AdminFileDialogComponent,
    AdminNoteFileDialogComponent,
    AdminConversationDialogNewComponent,
    UserProfileNewComponent,
    UserProfileIndividualNewComponent,
    UserProfileBusinessNewComponent,
    ChangeEmailDialogNewComponent,
    ChangeEmailSuccessDialogNewComponent,
    ConfirmEmailChangeNewComponent,
    ChangePhoneNumberDialogNewComponent,
    ChangePasswordDialogNewComponent,
    DashboardComponent,
    TransferComponent,
    StatementComponent,
    PaymentsComponent,
    AccountSectionComponent,
    AccountSectionHeaderComponent,
    ComingSoonHeaderComponent,
    OnboardingFormItemNewComponent,
    ReviewedInfoNewComponent,
    VerifyClientDialogComponent,
    ClientDecisionDialogComponent,
    VerificationDialogComponent,
    BillingSectionComponent,
    CardSectionEmptyStateComponent,
    ComingSoonSectionComponent,
    AccountSubSectionComponent,
    CardsDropdownComponent,
    InBetweenComponent,
    E2eNowComponent,
    ExternalComponent,
    MassPaymentsComponent,
    RecurringComponent,
    FavouritesComponent,
    AvatarInputNewComponent,
    AccountInformationDialogNewComponent,
    TransferDetailsFormComponent,
    CardActivationDialogComponent,
    SmallLoaderComponent,
    TransferConfirmationComponent,
    AccountListItemComponent,
    AccountsSelectorComponent,
    RegisteredUserComponent,
    RegisteredUserProfileNewComponent,
    RegisteredUserProfileIndividualNewComponent,
    RegisteredUserProfileCorporateNewComponent,
    RegisteredUserDocumentsComponent,
    RegisteredUserLogsComponent,
    LogDialogComponent,
    AccountSectionCardHeaderComponent,
    SubDashboardComponent,
    CardsComponent,
    IbanAccountComponent,
    WalletComponent,
    E2eAccountsSelectorComponent,
    OngoingRegistrationPdfNewComponent,
    ReportsComponent,
    ActionsComponent,
    NotificationsComponent,
    ActivationComponent,
    UpdateChidsComponent,
    ActivationFileUploadComponent,
    ExpiringDocumentsComponent,
    UserIpLoginComponent,
    AllDocumentsFormComponent,
    AllFileInputComponent,
    DocumentsTableComponent,
    BigLoaderNotCenteredComponent,
    InitialPaymentComponent,
    InitialPaymentFailComponent,
    InitialPaymentSuccessComponent,
    CardPaymentDialogComponent,
    OrderNewCardDialogComponent,
    StatementPdfComponent,
    ExtAccountsSelectorComponent,
    SubDashboardHeaderComponent,
    TemplateUploadComponent,
    MassPaymentTableComponent,
    ActivationFileTableComponent,
    MassPaymentsSnackbarComponent,
    ActivationSnackbarComponent,
    CurrencyExchangeComponent,
    BuyCurrencyDialogComponent,
    TopUpDialogComponent,
    TopUpResultDialogComponent,
    TransferSaveFavouriteComponent,
    StatementDetailsPdfComponent,
    SelectFavouriteOverlayComponent,
    SelectBeneficiaryOverlayComponent,
    CardsTabButtonComponent,
    CardItemComponent,
    LockUnlockCardDialogComponent,
    ShowPinDialogComponent,
    ReportCardComponent,
    DeactivatedCardsDialogComponent,
    RegisteredUserAccountsComponent,
    AccountsTableComponent,
    GenericAccountsTableComponent,
    IbanCertificateComponent,
    OnboardingLayoutNewComponent,
    ShowAmlHitsDialogComponent,
    ShowAmlHitDialogComponent,
    ShowKybHitsDialogComponent,
    ShowKybHitDialogComponent,
    CompanyCloseLinkDialogComponent,
    CompanyCloseLinkSelectDialogComponent,
    DetailedReportDialogComponent,
    FooterComponent,
    JsonViewerComponent,
  ],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    FormsModule,
    MaterialModule,
    MatLuxonDateModule,
    NgxFileDropModule,
    NgxIntlTelInputModule,
    PdfJsViewerModule,
    ReactiveFormsModule,
    AppRoutingModule,
    MatSlideToggleModule,
    NgxJsonViewerModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: () => sessionStorage.getItem('token'),
        allowedDomains: environment.ALLOWED_DOMAINS,
      },
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      registrationStrategy: 'registerImmediately',
    }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    { provide: LOCALE_ID, useValue: 'en-FI' },
    { provide: DateAdapter, useClass: StartWeekOnMondayDateAdapter },
    PendingChangesGuard,
    DecimalPipe,
    DatePipe,
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
