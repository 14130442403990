<div [formGroup]="declarationForm">
  <app-onboarding-form-item-new
    label="I acknowledge the industry that the individual is involved does NOT include: Arms & Weapons, Binary Options Trading, Greencard via Lotteries, Illegal downloads, Illegal Gambling, Illegal Pharmaceuticals, Illegal Tobacco sales, Pyramid and Ponzi Schemes, Timeshares, Unregistered Charities, Unregulated Financial Services, Shell banks and companies."
    [isReviewed]="isReviewed"
    [value]="getStringValue(onboardingData?.personalForm?.activitiesNotInclude)"
    [field]="
      fieldsMap.get(OnboardingFieldId.PERSONAL_DATA_ACTIVITIES_NOT_INCLUDE)
    "
  >
    <div fxLayout="column">
      <mat-checkbox
        color="primary"
        name="activitiesNotInclude"
        formControlName="activitiesNotInclude"
        data-testid="activities-checkbox"
      >
        <h2 class="question">
          I acknowledge the industry that the individual is involved does NOT
          include: Arms & Weapons, Binary Options Trading/Cryptocurrency,
          Greencard via Lotteries, Illegal downloads, Illegal Gambling, Illegal
          Pharmaceuticals, Illegal Tobacco sales, Pyramid and Ponzi Schemes,
          Timeshares, Unregistered Charities, Unregulated Financial Services,
          Shell banks and companies.
        </h2>
      </mat-checkbox>
      <br />
    </div>
  </app-onboarding-form-item-new>

  <app-onboarding-form-item-new
    label="I declare that the information that will be provided in this application form, is to the best of my knowledge, true, accurate and complete. I understand that I need to immediately inform eCREDO Limited about any changes."
    [isReviewed]="isReviewed"
    [value]="getStringValue(onboardingData?.personalForm?.isCorrectInformation)"
    [field]="
      fieldsMap.get(OnboardingFieldId.BUSINESS_DATA_IS_CORRECT_INFORMATION)
    "
  >
    <div fxLayout="column">
      <mat-checkbox
        color="primary"
        name="isCorrectInformation"
        formControlName="isCorrectInformation"
        data-testid="is-correct-information-checkbox"
      >
        <h2 class="question">
          I declare that the information that will be provided in this
          application form, is to the best of my knowledge, true, accurate and
          complete. I understand that I need to immediately inform eCREDO
          Limited about any changes.
        </h2>
      </mat-checkbox>
      <br />
    </div>
  </app-onboarding-form-item-new>

  <div fxLayoutAlign="space-between center">
    <button
      *ngIf="!isReviewed"
      mat-flat-button
      color="accent"
      class="g-small"
      data-testid="save-declaration-form-button"
      [disabled]="declarationForm.invalid || isSaving"
      [appButtonLoader]="isSaving"
      (click)="saveDesclarationForm()"
    >
      Submit
    </button>
  </div>
</div>

<!-- TEMPLATE -->
<ng-template #review let-label="label" let-value="value" let-field="field">
  <ng-container *ngIf="isReviewed">
    <div
      fxLayout="row"
      fxLayoutGap="20px grid"
      class="submitted"
      [class.comment]="field?.comment"
    >
      <div fxFlex="50" class="name">{{ label }}</div>
      <div fxFlex="50" class="value">{{ field?.commentedValue || value }}</div>
    </div>
    <div *ngIf="field?.comment" class="comment">
      {{ field?.comment }}
    </div>
  </ng-container>
</ng-template>
